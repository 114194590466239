/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Col, Input, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { Provinces } from "../types";

const CompanyFormDetails = () => {
    const colProps = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
    return (
        <Row>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Name</div>} name="name"
                >
                    <Input name="name" placeholder="Name" />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Email</div>} name="email"
                >
                    <Input name="email" placeholder="Email" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Phone</div>} name="phone"
                >
                    <Input name="phone" placeholder="Phone" maxLength={10} />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Fax</div>} name="fax"
                >
                    <Input name="fax" placeholder="Fax" maxLength={10} />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Website</div>} name="website"
                >
                    <Input name="website" placeholder="Website" />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Address 1</div>} name="address_1"
                >
                    <Input name="address_1" placeholder="Address 1" />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Address 2</div>} name="address_2"
                >
                    <Input name="address_2" placeholder="Address 2" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">City</div>} name="city"
                >
                    <Input name="city" placeholder="City" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Province</div>} name="province"
                >
                    <Select style={{ width: '100%' }}>
                        <Select.Option value={null}>Select a Province...</Select.Option>
                        {Object.values(Provinces).map((province) => (
                            <Select.Option key={province} value={province}>
                                {province}
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Postal Code</div>} name="postal_code"
                >
                    <Input name="postal_code" placeholder="Postal Code" maxLength={7} />
                </FormItem>
            </Col>

            {/* TODO@JESSE - THIS IS FOR COMPANY PREFERENCES */}
            {/* <Col {...colProps} style={{ padding: "0.25rem" }}>
                        <FormItem
                            label={<div className="input-label">GST Number</div>} name="gstNumber"
                        >
                            <Input name="gst_number" placeholder="GST Number" />
                        </FormItem>
                    </Col>

                    <Col {...colProps} style={{ padding: "0.25rem" }}>
                        <FormItem
                            label={<div className="input-label">Currency Symbol</div>} name="currencySymbol"
                        >
                            <Input name="currency_symbol" placeholder="Currency Symbol" />
                        </FormItem>
                    </Col>

                    <Col {...colProps} style={{ padding: "0.25rem" }}>
                        <FormItem
                            label={<div className="input-label">Timezone</div>} name="timeZone"
                        >
                            <Input name="timezone" placeholder="Timezone" />
                        </FormItem>
                    </Col> */}
        </Row>
    );
};

export default CompanyFormDetails;