import { Button, Form, Modal, } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useState } from "react";
import CompanyForm from "./CompanyForm";

const CompanyModal = (props: any) => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [form] = Form.useForm();
    const [file, setFile] = useState<RcFile | undefined>();
    const { company, push, updateCompany } = props;

    useEffect(() => {
        const values = form.getFieldsValue();
        Object.assign(values, company);
        form.setFieldsValue(values);
    }, [company, form]);

    const submitForm = useCallback(() => {
        const values = form.getFieldsValue();
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        if (file) {
            formData.append('logo', file);
        }
        updateCompany(company.id, formData, () => {
            push('/profile');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, file]);
    const onCancelModal = () => {
        setIsModalVisible(false);
        push("/profile");
    };
    return (
        <Modal
            centered
            visible={isModalVisible}
            onCancel={onCancelModal}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={onCancelModal} style={{ float: 'left' }}>
                    Cancel
                </Button>,
                <Button
                    form="update_company_profile"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                >
                    Save
                </Button>
            ]}
        >
            <CompanyForm form={form} submitForm={submitForm} setFile={setFile} />
        </Modal>
    );
};

export default CompanyModal;