/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { TableCurrentDataSource } from 'antd/lib/table/interface';
import { Table, Typography } from 'antd';
import type { TableProps } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import tableColumns from './columns';
import { Contract } from '../types';

export interface ContractType {
    value: string;
}

const ContractsTable = (props: any) => {
    const { push } = useHistory();
    const { data,
        user,
        searchValue,
        deleteContract,
        completeContract,
        cancelContract,
        downloadContractPDF,
        isDownloadingContractPDF,
        tablePropsChange,
        totalCount,
        principleQuantity,
        remainingQuantity,
        isFetchingContracts,
        contractNotifications
    } = props;
    const columns = tableColumns({ searchValue, deleteContract, completeContract, cancelContract, downloadContractPDF, isDownloadingContractPDF, contractNotifications }).filter(x => contractNotifications === undefined ? x.title !== 'Actions' : true);
    const onChange: TableProps<Contract>['onChange'] = (pagination, filters, sorter, extra: TableCurrentDataSource<Contract>) => {
        tablePropsChange(pagination, filters, sorter, extra);
    };
    return (
        <div>
            <Table
                rowClassName={(record) => record.purchaser_company.id === user.company_id && record.signature === null ? 'table-row-highlight' : ''}
                columns={columns}
                dataSource={data}
                onChange={onChange}
                scroll={{ x: 900 }}
                className="contract-table"
                rowKey="id"
                tableLayout='fixed'
                loading={isFetchingContracts}
                sticky
                pagination={{
                    total: totalCount
                }}
                summary={pageData => {
                    let totalQty = 0;
                    let remainingQty = 0;

                    totalQty = principleQuantity;
                    remainingQty = remainingQuantity;

                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{ backgroundColor: '#2e382f' }}>
                                <Table.Summary.Cell index={0} colSpan={5} />
                                <Table.Summary.Cell index={1}><Typography style={{ fontWeight: 'bolder', color: 'white' }}>Total Quantity</Typography></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    <Typography style={{ fontWeight: 'bolder', color: 'white' }}>{new Intl.NumberFormat('en-US').format(totalQty)} t</Typography>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    <Typography style={{ fontWeight: 'bolder', color: 'white' }}>{new Intl.NumberFormat('en-US').format(remainingQty)} t</Typography>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} colSpan={4} />
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
                // eslint-disable-next-line
                onRow={(record) => {
                    return {
                        onClick: event => {
                            if ((event.target as HTMLElement).tagName === 'TD' || (event.target as HTMLElement).tagName === 'SPAN') {
                                if (record.purchaser_company.id === user.company_id && (record.signature === null || record.status === 'SELLER_REQUESTED_EDIT')) {
                                    push(`contracts/edit/${record.id}/sign`);
                                } else if (record.completed || record.cancelled) {
                                    push(`contracts/edit/${record.id}`);
                                } else {
                                    push(`contracts/edit/${record.id}`);
                                }
                            }
                        },
                    };
                }} />
        </div>
    );
};

export default ContractsTable;
