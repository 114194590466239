/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Button,
  Tabs,
  Form,
  Spin,
} from "antd";
import { isObject, isString } from "utils/dataUtils";
import confirm from "antd/lib/modal/confirm";
import { CarOutlined, FormOutlined } from "@ant-design/icons";
import { useAuth } from "components/auth/authProvider";
import "styles/form.css";
import LoadsTable from "routes/loads/table/LoadsTable";
import { useLoadsApi } from "routes/loads/LoadsDataProvider";
import { useContractsApi } from "./ContractDataProvider";
import ContractForm from "./ContractForm";
import ContractStats from "./ContractStats";
import { Contract, EditRequest } from "./types";
import Signature from "./Signature";
import ContractActionSection from "./ContractActionSection";

const CreateContract = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const { loads, clearLoads, setEditLoad } = useLoadsApi();
  const { push } = useHistory();
  const { contractId, selectedTab } = props;
  const auth = useAuth();

  const [activeTab, setActiveTab] = useState(selectedTab);
  const [isSeller, setIsSeller] = useState(true);
  const [editRequest, setEditRequest] = useState<null | EditRequest>(null);
  const [modalTitle, setModalTitle] = useState("New Contract");
  const [submitButtonText, setSubmitButtonText] = useState<string | undefined>("Create");
  const [contract, setContract] = useState<Contract>();
  const [formChanged, setFormChanged] = useState(false);

  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const {
    postContract, updateContract, fetchContracts,
    isPostingContract, isUpdatingContract, fetchEditRequest,
    requestContractEdit, updateEditRequest
  } = useContractsApi();

  // FORM FUNCTIONS
  const runCancel = () => {

    setIsModalVisible(false);
    push("/contracts");
  };
  const onCancelModal = () => {
    setEditLoad();
    setEditMode(false);
    clearLoads();
    runCancel();
  };

  useEffect(() => {
    if (contractId) {
      const params = { ordering: '-id' };
      fetchContracts(contractId, params, (contract: Contract) => {
        setContract(contract);
        if (contract.status === 'SELLER_REQUESTED_EDIT') {
          fetchEditRequest(contractId, (value: EditRequest[]) => {
            if (value.length > 0) {
              setEditRequest(value[0]);
              if (!isSeller) {
                setActiveTab('sign');
              }
            }
          });
        }
      },
        () => {
          onCancelModal();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, contractId]);

  useEffect(() => {
    if (contract) {
      setIsSeller(contract.seller_company.id === auth.user.company_id);
    }
  }, [contract, auth.user.company_id]);

  useEffect(() => {
    if (contract) {
      let title = "Update Contract";
      let submitBtnText: string | undefined = "Update";
      if (contract.completed) {
        title = "Contract Completed";
        submitBtnText = undefined;
      } else if (contract.cancelled) {
        title = "Contract Cancelled";
        submitBtnText = undefined;
      } else if (contract.signature) {
        if (contract.seller_company.id === auth.user.company_id) {
          title = "Edit Contract";
          submitBtnText = "Submit Edit Request";
          if (activeTab === "loads") {
            submitBtnText = undefined;
          }
        } else {
          title = "Contract";
          submitBtnText = undefined;
        }
      }
      setModalTitle(title);
      setSubmitButtonText(submitBtnText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, contract, isSeller]);

  const prepDataFromValues = (values: any) => {
    let taxType: any = values.tax_type;
    if (isString(taxType)) {
      taxType = JSON.parse(taxType);
    }
    if (isObject(taxType) && Object.keys(taxType).length > 1) {
      // eslint-disable-next-line prefer-destructuring
      taxType = taxType[0];
    }
    return {
      ...values,
      purchase_date: values.purchase_date.format("YYYY-MM-DD"),
      start_date: values.date_range[0].format("YYYY-MM-DD"),
      end_date: values.date_range[1].format("YYYY-MM-DD"),
      purchaser_company: JSON.parse(values.purchaser_company).id,
      seller_company: JSON.parse(values.seller_company).id,
      tax_type: taxType,
    };
  };

  const prepDataForEditRequest = (data: any) => {
    const fieldsToEdit = data;
    // FIX DATA FOR TAX_TYPE
    if (fieldsToEdit.tax_type) {
      if (Array.isArray(fieldsToEdit.tax_type)) {
        // eslint-disable-next-line prefer-destructuring
        fieldsToEdit.tax_type = fieldsToEdit.tax_type[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        fieldsToEdit.tax_type = fieldsToEdit.tax_type.split('"')[1];
      }
    }
    // FIX DATA FOR DATE_RANGE
    if (fieldsToEdit.date_range) {
      const startDate = fieldsToEdit.date_range[0].format("YYYY-MM-DD");
      const endDate = fieldsToEdit.date_range[1].format("YYYY-MM-DD");
      delete fieldsToEdit.date_range;
      fieldsToEdit.start_date = startDate;
      fieldsToEdit.end_date = endDate;
    }
    // FIX DATA FOR PURCHASE_DATE
    if (fieldsToEdit.purchase_date) {
      fieldsToEdit.purchase_date = fieldsToEdit.purchase_date.format("YYYY-MM-DD");
    }
    return fieldsToEdit;
  };

  const prepDataFromContract = () => (
    {
      ...contract,
      purchaser_company: contract?.purchaser_company.id,
      seller_company: contract?.seller_company.id,
    }
  );

  const submitForm = useCallback(() => {
    const values = form.getFieldsValue();
    let datas = {};
    if (Object.keys(values).length > 0) {
      datas = prepDataFromValues(values);
    } else {
      datas = prepDataFromContract();
    }
    // IF ID WE ARE IN EDIT MODE
    if (contractId) {
      if (contract?.signature) {
        if (isSeller) {
          if (editRequest && editRequest.id) {
            const eid = (editRequest as EditRequest).id;
            const fieldsToEdit = prepDataForEditRequest({ ...(editRequest as EditRequest).requested_fields_to_edit, ...form.getFieldsValue(true, (meta) => meta.touched) });
            updateEditRequest(contractId, eid, { 'requested_fields_to_edit': fieldsToEdit }, () => { push('/contracts'); });
          } else {
            const fieldsToEdit = prepDataForEditRequest(form.getFieldsValue(true, (meta) => meta.touched));
            requestContractEdit(contractId, { 'requested_fields_to_edit': fieldsToEdit }, () => { push('/contracts'); });
          }
        }
      } else {
        updateContract(datas, contractId, () => {
          setIsModalVisible(false);
          push("/contracts");
        });
      }
    } else {
      postContract(datas, () => {
        setIsModalVisible(false);
        push("/contracts");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push, form, loads, contract, isSeller, editRequest, activeTab]);

  return (
    <Modal
      centered
      width={1200}
      visible={isModalVisible}
      maskClosable={false}
      onCancel={onCancelModal}
      footer={activeTab !== 'sign' && !editMode ? [
        <Button key="back" onClick={onCancelModal}>
          {activeTab === 'loads' ? 'Close' : 'Cancel'}
        </Button>,
        (submitButtonText &&
          <Button
            form="create_contract_form"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={contractId && !formChanged}
            loading={contractId ? isUpdatingContract : isPostingContract}
            onClick={activeTab === 'loads' && Object.keys(form.getFieldsValue()).length === 0 ? submitForm : () => { }}
          >
            {submitButtonText}
          </Button>
        ),
      ] : []}
    >

      <div className='contract-content'>
        {
          (!contract?.completed && !contract?.cancelled) &&
          <ContractActionSection isSeller={isSeller} status={contract?.status} contractId={contractId} editRequestId={(editRequest as EditRequest)?.id} ReviewAndSignAction={() => { setActiveTab('sign'); }} />
        }
        <Spin spinning={loading}>

          <Tabs style={{ height: '100%' }} onChange={setActiveTab} type='card' activeKey={activeTab}>
            <Tabs.TabPane style={{ height: '100%' }} tab='Contract' key='contract'>
              <ContractStats icon={<FormOutlined />} title={modalTitle} contractId={contractId} />
              <hr />
              <ContractForm id={contractId} submitForm={submitForm} form={form} contract={contract} setLoading={setLoading} setFormChanged={setFormChanged} />
            </Tabs.TabPane>
            {((!contract?.signature || (editRequest?.id && Object.keys(editRequest).length > 0)) && !isSeller) &&
              <Tabs.TabPane style={{ height: '100%' }} tab='Sign' key='sign'>
                <Signature contractId={contractId} contract={contract} isSeller={isSeller} editRequest={editRequest} setLoading={setLoading} />
              </Tabs.TabPane>
            }
            <Tabs.TabPane style={{ height: '100%' }} tab='Loads' key='loads'>
              <ContractStats icon={<CarOutlined />} title='Loads' contractId={contractId} />
              <hr />
              <LoadsTable pricePerTonne={contract?.price} contractId={contractId} isSigned={contract?.signature} status={contract?.status} isSeller={isSeller} editMode={editMode} setEditMode={setEditMode} setLoading={setLoading} setActiveTab={setActiveTab} />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </div>
    </Modal>
  );
};

export default CreateContract;
