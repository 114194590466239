import React from 'react';

import { Typography } from 'antd';

const styles = {
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    }
} as {[key: string]: React.CSSProperties};
export const NotFound = () => (
        <Typography.Title style={styles.title}>
            Page Not Found!
        </Typography.Title>
    );

export default NotFound;