import { useErrorsApi } from 'components/layouts/ErrorMessageProvider';
import React, { createContext, useContext, useState } from 'react';
import { Company } from 'routes/userManagement/types';

import { deleteData, getData, postData, updateData } from 'utils/network';
// Types
import { Contract, EditRequest } from './types';

const contractsApi = '/api/contracts/';

type ContractsById = { [id: string]: Contract }
type ContractNotification = { id: string, notifications: string[] }
interface ContractsAPIInterface {
    // FETCH CONTRACTS
    contracts: ContractsById,
    totalCount: number,
    principleQuantity: number,
    remainingQuantity: number,
    fetchContracts: (id?: number, params?: any, onSuccess?: (contract: Contract) => void, onError?: () => void) => void,
    isFetchingContracts: boolean,

    // CONTRACT NOTIFICATIONS
    contractNotifications: ContractNotification[],
    notificationCount: number,
    fetchContractNotifications: () => void,
    isFetchingContractNotifications: boolean,

    requestRefetch: boolean,
    setRequestRefetch: any,

    // POST CONTRACTS
    postContract: (datas: {}, onSuccess?: () => void) => void,
    isPostingContract: boolean,

    // UPDATE CONTRACT
    updateContract: (datas: {}, id: number, onSuccess?: () => void) => void,
    isUpdatingContract: boolean,

    // DELTE CONTRACT
    deleteContract: (id: number, onSuccess?: () => void) => void,
    isDeletingContract: boolean,

    // REQUEST CONTRACT EDIT
    requestContractEdit: (id: number, datas: {}, onSuccess?: () => void) => void,
    isRequestingContractEdit: boolean,

    // FETCH EDIT REQUEST
    fetchEditRequest: (id: number, onSuccess?: (editRequests: EditRequest[]) => void) => void,
    isFetchingEditRequest: boolean,

    // UPDATE EDIT REQUEST SIGNATURE
    updateEditRequestSignature: (id: number, eid: number, datas: string, onSuccess?: () => void) => void,
    isUpdatingEditRequestSignature: boolean,

    // UPDATE EDIT REQUEST SIGNATURE
    updateEditRequest: (id: number, eid: number, datas: {}, onSuccess?: () => void) => void,
    isUpdatingEditRequest: boolean,

    // UPDATE EDIT REQUEST
    deleteEditRequest: (id: number, eid: number, onSuccess?: () => void) => void,
    isDeletingEditRequest: boolean,

    // COMPLETE CONTRACT
    completeContract: (id: number, purchaser: boolean, declineUndo: boolean, onSuccess?: () => void) => void,
    isCompletingContract: boolean,

    // CANCEL CONTRACT
    cancelContract: (id: number, purchaser: boolean, declineUndo: boolean, onSuccess?: () => void) => void,
    isCancellingContract: boolean,

    // UPDATE CONTRACT SIGNATURE
    updateContractSignature: (id: number, datas: string, onSuccess?: () => void) => void,
    isUpdatingContractSignature: boolean,

    // DOWNLOAD CONTRACT PDF
    downloadContractPDF: (id: number, onSuccess?: () => void) => void,
    isDownloadingContractPDF: boolean,

    // TAX OPTIONS
    taxOptions: any[],
    fetchTaxOptions: () => void,
    isFetchingTaxOptions: boolean,

    // COMMODITIES
    commodities: any[],
    fetchCommodities: () => void,
    isFetchingCommodities: boolean,

    // COMPANIES
    companies: Company[],
    fetchCompanies: (userCompany?: string) => void,
    isFetchingCompanies: boolean,
}

const decodeContractResponse = (data: Contract[] | Contract) => {
    if (Array.isArray(data)) {
        return data.reduce((acc, contract) => {
            acc[contract.id] = contract;
            return acc;
        }, {} as ContractsById);
    }
    if (data) {
        return { [data.id]: data };
    }
    return {};
};

function useProvideContractsApi(): ContractsAPIInterface {

    // ERRORS
    const { addError } = useErrorsApi();

    // FETCH CONTRACTS
    const [contracts, setContracts] = useState<ContractsById>({});
    const [totalCount, setTotalCount] = useState<number>(-1);
    const [principleQuantity, setPrincipleQuantity] = useState<number>(-1);
    const [remainingQuantity, setRemainingQuantity] = useState<number>(-1);
    const [isFetchingContracts, setIsFetchingContracts] = useState(false);
    const [requestRefetch, setRequestRefetch] = useState(false);

    const fetchContracts = (id?: number, params?: any, onSuccess?: (contract: Contract) => void, onError?: () => void) => {
        const getContractsApi = `${contractsApi}${id ? `${id}` : ''}`;
        const queryParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
            queryParams.append(key.toString(), params[key].toString());
        });
        const getContractRequest = getData(`${getContractsApi}?${queryParams.toString()}`);
        setIsFetchingContracts(true);
        getContractRequest.then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    let decodedData;
                    if (data.results) {
                        decodedData = decodeContractResponse(data.results);
                        setTotalCount(data.count);
                        setPrincipleQuantity(data.principle_quantity);
                        setRemainingQuantity(data.quantity_remaining);
                    } else {
                        decodedData = decodeContractResponse(data);
                    }
                    if (onSuccess && id) {
                        onSuccess(decodedData[String(id)]);
                        setIsFetchingContracts(false);
                        return;
                    }
                    setContracts({ ...decodedData });
                    setIsFetchingContracts(false);
                    setRequestRefetch(false);
                });
            } else {
                if (onError && id) {
                    onError();
                }
                addError(`Error fetching contracts: ${response.statusText}`);
                setIsFetchingContracts(false);
            }

        });
    };

    // FETCH CONTRACT NOTIFICATIONS
    const [contractNotifications, setContractNotifications] = useState<ContractNotification[]>([]);
    const [notificationCount, setNotificationCount] = useState<number>(-1);
    const [isFetchingContractNotifications, setIsFetchingContractNotifications] = useState(false);

    const fetchContractNotifications = () => {
        const getContractNotificationsApi = `${contractsApi}notifications/`;
        const getContractNotificationsRequest = getData(`${getContractNotificationsApi}`);

        setIsFetchingContractNotifications(true);
        getContractNotificationsRequest.then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    setContractNotifications(data);
                    setNotificationCount(contractNotifications.length);
                    setIsFetchingContractNotifications(false);
                });
            } else {
                addError(`Error fetching contract notifications: ${response.statusText}`);
                setIsFetchingContractNotifications(false);
            }
        });
    };

    // POST CONTRACT
    const [isPostingContract, setIsPostingContract] = useState(false);
    const postContract = (datas: {}, onSuccess?: () => void) => {
        setIsPostingContract(true);
        postData(contractsApi, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeContractResponse(data);
                    if (onSuccess) {
                        onSuccess();
                    }
                    setContracts({ ...contracts, ...decodedData });
                    setIsPostingContract(false);
                    setRequestRefetch(true);
                });
            } else {
                addError(`Error saving contract: ${response.statusText}`);
                setIsPostingContract(false);
            }
        });
    };

    // UPDATE CONTRACT
    const [isUpdatingContract, setIsUpdatingContract] = useState(false);
    const updateContract = (datas: {}, id: number, onSuccess?: () => void) => {
        setIsUpdatingContract(true);
        updateData(`${contractsApi}${id}/`, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeContractResponse(data);
                    if (onSuccess) {
                        onSuccess();
                    }
                    setContracts({ ...contracts, ...decodedData });
                    setIsUpdatingContract(false);
                    setRequestRefetch(true);
                });
            } else {
                addError(`Error updating contracts: ${response.statusText}`);
                setIsUpdatingContract(false);
            }

        });
    };

    // DELETE CONTRACT
    const [isDeletingContract, setIsDeletingContract] = useState(false);
    const deleteContract = (id: number, onSuccess?: () => void) => {
        const deleteContractApi = `${contractsApi}${id}/`;
        setIsDeletingContract(true);
        deleteData(deleteContractApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                delete contracts[id];
                setContracts({ ...contracts });
                if (onSuccess) {
                    onSuccess();
                }
                setIsDeletingContract(false);
                setRequestRefetch(true);
            } else {
                addError(`Error deleting contract: ${response.statusText}`);
                setIsDeletingContract(false);
            }
        });
    };

    const [isRequestingContractEdit, setIsRequestingContractEdit] = useState(false);
    const requestContractEdit = (id: number, datas: {}, onSuccess?: () => void) => {
        const requestContractEditApi = `${contractsApi}${id}/request-edit/`;
        setIsRequestingContractEdit(true);
        postData(requestContractEditApi, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                if (onSuccess) {
                    onSuccess();
                }
                setIsRequestingContractEdit(false);
                setRequestRefetch(true);
            } else {
                addError(`Error creating edit request: ${response.statusText}`);
                setIsRequestingContractEdit(false);
            }
        });
    };

    const [isFetchingEditRequest, setIsFetchingEditRequest] = useState(false);
    const fetchEditRequest = (id: number, onSuccess?: (editRequests: EditRequest[]) => void) => {
        const fetchEditRequestApi = `${contractsApi}${id}/request-edit/`;
        setIsFetchingEditRequest(true);
        getData(fetchEditRequestApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    if (onSuccess) {
                        onSuccess(data);
                    }
                });
                setIsFetchingEditRequest(false);
            } else {
                addError(`Error fetching edit request: ${response.statusText}`);
                setIsFetchingEditRequest(false);
            }
        });
    };

    const [isUpdatingEditRequestSignature, setIsUpdatingEditRequestSignature] = useState(false);
    const updateEditRequestSignature = (id: number, eid: number, datas: string, onSuccess?: () => void) => {
        const updateEditRequestSignatureApi = `${contractsApi}${id}/request-edit/${eid}/sign/`;
        setIsUpdatingEditRequestSignature(true);
        updateData(updateEditRequestSignatureApi, { purchaser_signature: datas }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                setContracts({ ...contracts });
                if (onSuccess) {
                    onSuccess();
                }
                setIsUpdatingEditRequestSignature(false);
                setRequestRefetch(true);
            } else {
                addError(`Error signing edit request: ${response.statusText}`);
                setIsUpdatingEditRequestSignature(false);
            }
        });
    };
    // DECLINE EDIT REQUEST, UNDO EDIT REQUEST, DECLINE COMPLETION REQUEST, UNDO COMPLETION REQUEST, DENY CANCELLATION REQUEST, UNDO CANCELLATION REQUEST
    const [isUpdatingEditRequest, setIsUpdatingEditRequest] = useState(false);
    const updateEditRequest = (id: number, eid: number, datas: {}, onSuccess?: () => void) => {
        const updateEditRequestApi = `${contractsApi}${id}/request-edit/${eid}/`;
        setIsUpdatingEditRequest(true);
        updateData(updateEditRequestApi, datas).then((response: Response) => {
            if (response.status >= 200 && response.status < 300) {
                setContracts({ ...contracts });
                response.json().then(() => {
                    if (onSuccess) {
                        onSuccess();
                    }
                    setIsUpdatingEditRequest(false);
                    setRequestRefetch(true);
                });
            } else {
                addError(`Error updating edit request: ${response.statusText}`);
                setIsUpdatingEditRequest(false);
            }
        });
    };

    const [isDeletingEditRequest, setIsDeletingEditRequest] = useState(false);
    const deleteEditRequest = (id: number, eid: number, onSuccess?: () => void) => {
        const deleteEditRequestApi = `${contractsApi}${id}/request-edit/${eid}/`;
        setIsDeletingEditRequest(true);

        deleteData(deleteEditRequestApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                setContracts({ ...contracts });
                if (onSuccess) {
                    onSuccess();
                }
                setIsDeletingEditRequest(false);
                setRequestRefetch(true);
            } else {
                addError(`Error deleting edit request: ${response.statusText}`);
                setIsDeletingEditRequest(false);
            }
        });
    };

    const [isCompletingContract, setIsCompletingContract] = useState(false);
    const completeContract = (id: number, purchaser: boolean, declineUndo: boolean = false, onSuccess?: () => void) => {
        const completeContractApi = `${contractsApi}${id}/complete/`;
        setIsCompletingContract(true);
        const completeData: any = { 'id': id };
        if (declineUndo) {
            completeData.purchaser_requested_completion = false;
            completeData.seller_requested_completion = false;
        } else if (purchaser) {
            completeData.purchaser_requested_completion = true;
        } else {
            completeData.seller_requested_completion = true;
        }
        updateData(completeContractApi, completeData).then((response: Response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data: Contract) => {
                    const decodedData = decodeContractResponse(data);
                    setContracts({ ...decodedData, ...contracts });
                    if (onSuccess) {
                        onSuccess();
                    }
                    setIsCompletingContract(false);
                    setRequestRefetch(true);
                });
            } else {
                addError(`Error completing contract: ${response.statusText}`);
                setIsCompletingContract(false);
            }
        });
    };

    const [isCancellingContract, setIsCancellingContract] = useState(false);
    const cancelContract = (id: number, purchaser: boolean, declineUndo: boolean = false, onSuccess?: () => void) => {
        const cancelContractApi = `${contractsApi}${id}/cancellation/`;
        setIsCancellingContract(true);

        const cancelData: any = { 'id': id };
        if (declineUndo) {
            cancelData.purchaser_requested_cancellation = false;
            cancelData.seller_requested_cancellation = false;
        } else if (purchaser) {
            cancelData.purchaser_requested_cancellation = true;
        } else {
            cancelData.seller_requested_cancellation = true;
        }

        updateData(cancelContractApi, cancelData).then((response: Response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data: Contract) => {
                    const decodedData = decodeContractResponse(data);
                    setContracts({ ...decodedData, ...contracts });
                    if (onSuccess) {
                        onSuccess();
                    }
                    setIsCancellingContract(false);
                    setRequestRefetch(true);
                });
            } else {
                addError(`Error cancelling contract: ${response.statusText}`);
                setIsCancellingContract(false);
            }
        });
    };

    const [isUpdatingContractSignature, setIsUpdatingContractSignature] = useState(false);
    const updateContractSignature = (id: number, datas: string, onSuccess?: () => void) => {
        const updateContractSignatureApi = `${contractsApi}${id}/signature/`;
        setIsUpdatingContractSignature(true);
        updateData(updateContractSignatureApi, { signature: datas }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                contracts[id].signature = 'success';
                setContracts({ ...contracts });
                if (onSuccess) {
                    onSuccess();
                }
                setIsUpdatingContractSignature(false);
                setRequestRefetch(true);
            } else {
                addError(`Error signing contract: ${response.statusText}`);
                setIsUpdatingContractSignature(false);
            }
        });
    };

    const [isDownloadingContractPDF, setIsDownloadingContractPDF] = useState(false);
    const downloadContractPDF = (id: number, onSuccess?: () => void) => {
        const downloadContractPDFApi = `${contractsApi}${id}/pdf/`;
        setIsDownloadingContractPDF(true);
        getData(downloadContractPDFApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.blob()
                    .then(data => window.open(URL.createObjectURL(data)));
                if (onSuccess) {
                    onSuccess();
                }
                setIsDownloadingContractPDF(false);
            } else {
                addError(`Error downloading contract PDF: ${response.statusText}`);
                setIsDownloadingContractPDF(false);
            }
        });
    };

    // FETCH TAX OPTIONS
    const [taxOptions, setTaxOptions] = useState([]);
    const [isFetchingTaxOptions, setIsFetchingTaxOptions] = useState(false);

    const fetchTaxOptions = () => {
        setIsFetchingTaxOptions(true);
        const getTaxRatesApi = "/api/contracts/tax-choices/";
        const getTaxOptionsRequest = getData(getTaxRatesApi);
        getTaxOptionsRequest.then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data) => {
                    setTaxOptions(data);
                    setIsFetchingTaxOptions(false);
                });
            } else {
                addError(`Error fetching tax options: ${response.statusText}`);
                setIsFetchingTaxOptions(false);
            }
        });
    };

    // FETCH COMMODITIES
    const [commodities, setCommodities] = useState([]);
    const [isFetchingCommodities, setIsFetchingCommodities] = useState(false);

    const fetchCommodities = () => {
        setIsFetchingCommodities(true);
        const getCommoditiesApi = "/api/contracts/commodities/";
        const getCommoditiesRequest = getData(getCommoditiesApi);
        getCommoditiesRequest.then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data) => {
                    setCommodities(data);
                    setIsFetchingCommodities(false);
                });
            } else {
                addError(`Error fetching commodities: ${response.statusText}`);
                setIsFetchingCommodities(false);
            }
        });
    };


    // FETCH COMPANIES
    const [companies, setCompanies] = useState([]);
    const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
    const fetchCompanies = () => {
        setIsFetchingCompanies(true);
        const getCompaniesApi = "/api/companies/";
        const getCompaniesRequest = getData(getCompaniesApi);
        getCompaniesRequest.then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data) => {
                    setCompanies(data);
                    setIsFetchingCompanies(false);
                });
            } else {
                addError(`Error fetching companies: ${response.statusText}`);
                setIsFetchingCompanies(false);
            }
        });
    };
    return {
        // FETCH CONTRACTS
        contracts,
        totalCount,
        principleQuantity,
        remainingQuantity,
        fetchContracts,
        isFetchingContracts,

        // CONTRACT NOTIFICATIONS
        contractNotifications,
        notificationCount,
        fetchContractNotifications,
        isFetchingContractNotifications,

        requestRefetch,
        setRequestRefetch,

        // POST CONTRACT
        postContract,
        isPostingContract,

        // UPDATE CONTRACT
        updateContract,
        isUpdatingContract,

        // DELETE CONTRACT
        deleteContract,
        isDeletingContract,

        // REQUEST CONTRACT EDIT
        requestContractEdit,
        isRequestingContractEdit,

        // FETCH EDIT REQUEST
        fetchEditRequest,
        isFetchingEditRequest,

        // UPDATE EDIT REQUEST SIGNATURE
        updateEditRequestSignature,
        isUpdatingEditRequestSignature,

        // UPDATE EDIT REQUEST
        updateEditRequest,
        isUpdatingEditRequest,

        // DECLINE EDIT REQUEST
        // UNDO EDIT REQUEST
        // THE ABOVE WILL BE HANDLED WITH DELETE EDIT REQUEST
        deleteEditRequest,
        isDeletingEditRequest,

        // COMPLETE_CONTRACT
        completeContract,
        isCompletingContract,

        // CANCEL CONTRACT
        cancelContract,
        isCancellingContract,

        // POST SIGNATURE
        updateContractSignature,
        isUpdatingContractSignature,

        // DOWNLOAD CONTRACT PDF
        downloadContractPDF,
        isDownloadingContractPDF,

        // TAX OPTIONS
        taxOptions,
        fetchTaxOptions,
        isFetchingTaxOptions,

        // COMMODITIES
        commodities,
        fetchCommodities,
        isFetchingCommodities,

        // COMPANIES
        companies,
        fetchCompanies,
        isFetchingCompanies
    };
}

const contractsContext = createContext<ContractsAPIInterface>({
    // FETCH CONTRACTS
    contracts: {},
    totalCount: 0,
    principleQuantity: 0,
    remainingQuantity: 0,
    fetchContracts: () => { },
    isFetchingContracts: false,

    // CONTRACT NOTIFICATIONS
    contractNotifications: [],
    notificationCount: 0,
    fetchContractNotifications: () => { },
    isFetchingContractNotifications: false,

    requestRefetch: false,
    setRequestRefetch: () => { },

    // POST CONTRACT
    postContract: () => { },
    isPostingContract: false,

    // UPDATE CONTRACT
    updateContract: () => { },
    isUpdatingContract: false,

    // DELETE CONTRACT
    deleteContract: () => { },
    isDeletingContract: false,

    // REQUEST CONTRACT EDIT
    requestContractEdit: () => { },
    isRequestingContractEdit: false,

    // FETCH EDIT REQUEST
    fetchEditRequest: () => { },
    isFetchingEditRequest: false,

    // UPDATE EDIT REQUEST SIGNATURE
    updateEditRequestSignature: () => { },
    isUpdatingEditRequestSignature: false,

    // UPDATE EDIT REQUEST
    updateEditRequest: () => { },
    isUpdatingEditRequest: false,

    // DECLINE EDIT REQUEST
    // UNDO EDIT REQUEST
    // THE ABOVE WILL BE HANDLED WITH DELETE EDIT REQUEST
    deleteEditRequest: () => { },
    isDeletingEditRequest: false,

    // COMPLETE CONTRACT
    completeContract: () => { },
    isCompletingContract: false,

    // CANCEL CONTRACT
    cancelContract: () => { },
    isCancellingContract: false,

    // POST SIGNATURE
    updateContractSignature: () => { },
    isUpdatingContractSignature: false,

    // DOWNLOAD CONTRACT PDF
    downloadContractPDF: () => { },
    isDownloadingContractPDF: false,

    // TAX OPTIONS
    taxOptions: [],
    fetchTaxOptions: () => { },
    isFetchingTaxOptions: false,

    // COMMODITIES
    commodities: [],
    fetchCommodities: () => { },
    isFetchingCommodities: false,

    // COMPANIES
    companies: [],
    fetchCompanies: () => { },
    isFetchingCompanies: false,
});
export default function ProvideContractsContext({ children }: { children: React.ReactNode }) {
    const auth = useProvideContractsApi();
    return <contractsContext.Provider value={auth}>{children}</contractsContext.Provider>;
};

export const useContractsApi = () => {
    const contractsContextApi = useContext(contractsContext);
    return contractsContextApi;
};
