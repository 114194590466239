/* eslint-disable @typescript-eslint/no-unused-vars */

import { Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLoadsApi } from "./LoadsDataProvider";
import UploadAttachment from "./UploadAttachment";

const LoadAttachments = (props: any) => {
    const { loadId } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [attachmentCount, setAttachmentCount] = useState(-1);
    const [attachments, setAttachments] = useState({});
    const { fetchLoadAttachments, isFetchingLoadAttachments } = useLoadsApi();

    useEffect(() => {
        if (attachmentCount < 0 && !isFetchingLoadAttachments) {
            fetchLoadAttachments(loadId, (data) => {
                setAttachmentCount(data.length);
                setAttachments(data);
                setIsLoaded(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingLoadAttachments, attachmentCount]);


    return (
        <div className="load-attachments">
            <Typography.Title level={4}>Attachments</Typography.Title>
            <Spin spinning={!isLoaded}>
                <UploadAttachment loadId={loadId} attachments={attachments} />
            </Spin>
        </div>
    );
};

export default LoadAttachments;