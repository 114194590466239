import React from 'react';
import { Route, Switch, } from 'react-router-dom';

import ProtectedRoute from 'components/auth/ProtectedRoute';
// Components
import NotFound from 'components/auth/NotFound';
import Contracts from 'routes/contracts/Contracts';
import UserManagement from 'routes/userManagement/UserManagement';
import CreateUser from './routes/signup/CreateUser';
import LoginPage from './routes/login/LoginPage';
import Dashboard from './routes/dashboard/Dashboard';
// import Loads from './routes/loads/Loads';

const App = () => (
  <Switch>
    <Route path="/login" component={LoginPage} />
    <Route path="/signup" component={CreateUser} />
    <ProtectedRoute exact path="/" component={Dashboard} />
    <ProtectedRoute path="/contracts" component={Contracts} exact={false} />
    {/* <ProtectedRoute path="/loads" component={Loads} exact={false} /> */}
    <ProtectedRoute path="/profile" component={UserManagement} exact={false} />
    <ProtectedRoute path={undefined} component={NotFound} exact={false} />
  </Switch>
);

export default App;
