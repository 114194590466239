import React, { createContext, useContext, useState } from 'react';

interface ErrorAPIInterface {
    errors: string[]
    addError: (error: string) => void
    resetErrors: () => void
};
function useProvideErrorMessageApi(): ErrorAPIInterface {
    const [errors, setErrors] = useState<string[]>([]);
    const addError = (error: string) => {
        setErrors([...errors, error]);
    };
    const resetErrors = () => {
        setErrors([]);
    };
    return {
        errors,
        addError,
        resetErrors
    };
};
const errorContext = createContext<ErrorAPIInterface>({
    errors: [],
    addError: () => { },
    resetErrors: () => { },
});
export default function ProvideErrorContext({ children }: { children: React.ReactNode }) {
    const auth = useProvideErrorMessageApi();
    return <errorContext.Provider value={auth}>{children}</errorContext.Provider>;
};
export const useErrorsApi = () => {
    const contractsContextApi = useContext(errorContext);
    return contractsContextApi;
};
