import { Button, Form, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import UserForm from "./UserForm";

const UserModal = ({ user, push, updateUser }: any) => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [form] = Form.useForm();
    const [canCreateContracts, setCanCreateContracts] = useState(false);
    const [canAccessContracts, setCanAccessContracts] = useState(false);
    const [canEditContracts, setCanEditContracts] = useState(false);
    const [canAccessContractsHistory, setCanAccessContractsHistory] = useState(false);
    const [canAccessReportsAndInvoicing, setCanAccessReportsAndInvoicing] = useState(false);
    const [canAccessUsers, setCanAccessUsers] = useState(false);
    const [canAccessCompanyInfo, setCanAccessCompanyInfo] = useState(false);
    const [canAccessCompanyPreferences, setCanAccessCompanyPreferences] = useState(false);


    useEffect(() => {
        const values = form.getFieldsValue();
        Object.assign(values, user);

        form.setFieldsValue(values);
        setCanCreateContracts(values.can_create_contracts);
        setCanAccessContracts(values.can_access_contracts);
        setCanEditContracts(values.can_edit_contracts);
        setCanAccessContractsHistory(values.can_access_contractshistory);
        setCanAccessReportsAndInvoicing(values.can_access_reports_andinvoicing);
        setCanAccessUsers(values.can_access_users);
        setCanAccessCompanyInfo(values.can_access_company_info);
        setCanAccessCompanyPreferences(values.can_access_company_preferences);

    }, [user, form]);

    const submitForm = useCallback(() => {
        const values = form.getFieldsValue();

        const datas = {
            ...values,
        };
        Object.keys(datas).forEach(key => {
            if (!datas[key]) { datas[key] = undefined; }
        });
        datas.can_create_contracts = Number(canCreateContracts);
        datas.can_access_contracts = Number(canAccessContracts);
        datas.can_edit_contracts = Number(canEditContracts);
        datas.can_access_contractshistory = Number(canAccessContractsHistory);
        datas.can_access_reports_andinvoicing = Number(canAccessReportsAndInvoicing);
        datas.can_access_users = Number(canAccessUsers);
        datas.can_access_company_info = Number(canAccessCompanyInfo);
        datas.can_access_company_preferences = Number(canAccessCompanyPreferences);

        const formData = new FormData();
        Object.keys(datas).forEach(key => {
            formData.append(key, datas[key]);
        });

        updateUser(user.id, formData, () => {
            push('/profile');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, canCreateContracts, canAccessContracts, canEditContracts, canAccessContractsHistory, canAccessReportsAndInvoicing, canAccessUsers, canAccessCompanyInfo, canAccessCompanyPreferences]);

    const onCancelModal = () => {
        setIsModalVisible(false);
        push("/profile");
    };

    return (
        <Modal
            centered
            visible={isModalVisible}
            onCancel={onCancelModal}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={onCancelModal} style={{ float: 'left' }}>
                    Cancel
                </Button>,
                <Button
                    form="user_form"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                >
                    Save
                </Button>
            ]}
        >
            <UserForm user={user} form={form} submitForm={submitForm} props={{ canCreateContracts, setCanCreateContracts, canAccessContracts, setCanAccessContracts, canEditContracts, setCanEditContracts, canAccessContractsHistory, setCanAccessContractsHistory, canAccessReportsAndInvoicing, setCanAccessReportsAndInvoicing, canAccessUsers, setCanAccessUsers, canAccessCompanyInfo, setCanAccessCompanyInfo, canAccessCompanyPreferences, setCanAccessCompanyPreferences }} />
        </Modal>
    );
};

export default UserModal;