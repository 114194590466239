/* eslint-disable react/jsx-props-no-spreading */
import { Col, Input, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React from "react";

const UserFormDetails = ({ isCreate = false, isAdmin, handleEmployeeTypeChange }: { isCreate: boolean, isAdmin: boolean, handleEmployeeTypeChange: any }) => {
    const colProps = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
    const employeeTypeColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

    return (
        <Row>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    rules={[{ required: true, message: "First Name is required." }]}
                    label={<div className="input-label">First Name</div>} name="first_name"
                >
                    <Input name="first_name" placeholder="First Name" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    rules={[{ required: true, message: "Last Name is required." }]}
                    label={<div className="input-label">Last Name</div>} name="last_name"
                >
                    <Input name="last_name" placeholder="Last Name" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    rules={[{ required: true, message: "Email is required." }]}
                    label={<div className="input-label">Email</div>} name="email"
                >
                    <Input name="email" placeholder="Email" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Phone</div>} name="user_phone"
                >
                    <Input name="user_phone" placeholder="Phone" max={10} />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Fax</div>} name="user_fax"
                >
                    <Input name="user_fax" placeholder="Fax" max={10} />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Job Title</div>} name="job_title"
                >
                    <Input name="job_title" placeholder="Job Title" />
                </FormItem>
            </Col>


            {isAdmin &&
                <Col {...employeeTypeColProps} >
                    <FormItem
                        rules={[{ required: true, message: "Employee Type is required." }]}
                        label={<div className="input-label">Employee Type</div>} name="employee_type"
                    >
                        <Select onChange={(value) => { handleEmployeeTypeChange(value); }} placeholder="Employee Type" style={{ width: '50%', padding: "0.25rem" }} >
                            <Select.Option value="User">User</Select.Option>
                            <Select.Option value="Admin">Admin</Select.Option>
                        </Select>
                    </FormItem>
                </Col>
            }

            {/* PASSWORD RESET */}
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Password</div>} name="password"
                    rules={isCreate ? [
                        { required: true, message: 'Please input your password!' },
                        { min: 6, message: 'Password must be minimum 6 characters.' },] : [
                        { min: 6, message: 'Password must be minimum 6 characters.' },
                    ]}
                >
                    <Input.Password name="password" placeholder="Password" />
                </FormItem>
            </Col>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Confirm Password</div>} name="confirm_password"
                    rules={isCreate ? [{ required: true, message: 'Please confirm your password!' },
                    { min: 6, message: 'Password must be minimum 6 characters.' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ] : [
                        { min: 6, message: 'Password must be minimum 6 characters.' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password name="confirm_password" placeholder="Confirm Password" />
                </FormItem>
            </Col>
        </Row >
    );
};

export default UserFormDetails;