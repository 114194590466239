/* eslint no-param-reassign: 0 */
/* eslint react/prop-types: 0 */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import { valueOrNotSpecified } from "utils/dataUtils";
import { useContractsApi } from "./ContractDataProvider";

const Signature = (props: any) => {

    const [signatureResult, setSignatureResult] = useState('');
    const sigCanvas = useRef({});
    const sigPad = useRef({});
    const { updateContractSignature, updateEditRequestSignature } = useContractsApi();
    const { push } = useHistory();
    const { contractId, contract, isSeller, editRequest, setLoading } = props;

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const measuredRef = useCallback(node => {
        const resizeCanvas = (signaturePad: { clear: () => void; }, canvas: { width: any; parentElement: { clientWidth: number; }; height: number; }) => {
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientWidth / 5;
            signaturePad.clear();
        };

        if (node !== null) {
            sigCanvas.current = node.getCanvas();
            sigPad.current = node.getSignaturePad();
            resizeCanvas(node.getSignaturePad(), node.getCanvas());
        }
    }, []);

    const setSignatureOnChange = () => {
        const dataURL = (sigCanvas.current as SignatureCanvas).toDataURL();
        setSignatureResult(dataURL);
    };

    const saveInput = () => {
        const cleanSignatureResult = signatureResult.replace('data:image/png;base64,', '');
        if (editRequest && Object.keys(editRequest).length > 0) {
            updateEditRequestSignature(contractId, editRequest.id, cleanSignatureResult, () => {
                push('/contracts');
            });
        } else {
            updateContractSignature(contractId, cleanSignatureResult, () => {
                push('/contracts');
            });
        }
    };

    const clearInput = () => {
        (sigPad.current as SignaturePad).clear();
        setSignatureResult('');
    };

    const GetDifferences = (): JSX.Element | JSX.Element[] => {
        if (Object.keys(editRequest).length > 0 && contract) {
            const results: JSX.Element | JSX.Element[] = [];
            results.push(<div key='differences'>
                <Typography.Title level={3}>Changes Requested</Typography.Title>
            </div>);
            Object.keys((editRequest.requested_fields_to_edit)).forEach(key => {
                results.push(<div key={key}>
                    <Typography.Title level={5}>{key} has changed from <b>{valueOrNotSpecified(contract[key])}</b> to <b>{valueOrNotSpecified(editRequest.requested_fields_to_edit[key])}</b></Typography.Title>
                </div>);
            });
            const differences = <div style={{ backgroundColor: '#fffbe6', border: '1px solid #ffe58f', padding: '1rem', borderRadius: '0.5rem', marginBottom: '0.5rem' }}>
                {results}
            </div>;
            return differences;
        }
        return <></>;
    };

    return (
        <div>
            <Row>
                <Typography.Title>Signature</Typography.Title>
            </Row>

            {/* IF WE HAVE AN EDIT REQUEST WE NEED TO SHOW CHANGES */}
            {
                editRequest && !isSeller &&
                GetDifferences()
            }

            <Row style={{ border: '4px dashed grey' }}>
                <SignatureCanvas ref={measuredRef} penColor='black'
                    canvasProps={{ className: 'sigCanvas' }}
                    onEnd={setSignatureOnChange} />
            </Row>
            <Row style={{ paddingTop: '1rem' }}>
                <Col span={4} offset={8}>
                    <Button key="Clear Signature" style={{ width: '100%' }} type="default" onClick={clearInput}>
                        Clear
                    </Button>
                </Col>
                <Col span={4}>
                    <Button key="Save Signature" style={{ width: '100%' }} type="primary" disabled={!signatureResult} onClick={saveInput}>
                        Save
                    </Button>
                </Col>
            </Row>
        </div >
    );
};
export default Signature;