import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Card, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { useLoadsApi } from 'routes/loads/LoadsDataProvider';
// import GraphicalReportingCard from './Reporting/GraphicalReportingCard';

const styles = {
    dashboardCard: {
        width: '80%',
        margin: 'auto',
        height: 'fit-content',
        boxShadow: '0px 0px 5px 1px #4daa48'
    },
    dashboardError: {
        width: '80%',
        margin: 'auto',
        height: 'fit-content',
        marginBottom: '1rem',
    },
    cardItem: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    loadingSpinner: {
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        alignItems: 'center'
    },
} as { [key: string]: React.CSSProperties };
const Dashboard = () => {
    const { fetchLoads, isFetchingLoads } = useLoadsApi();
    const { push } = useHistory();
    const onSearchLoad = (value: any) => {
        fetchLoads(value as number, (load) => {
            push(`contracts/edit/${load.contract}/loads/${load.id}`);
        });
    };
    const onSearchContract = (value: any) => {
        push(`contracts/edit/${value}`);
    };
    return (
        <div className='dashboard'>
            <Card title="Quick Use" style={styles.dashboardCard}>
                <div style={styles.cardItem}>
                    <Link to='/contracts/create'>Create New Contract</Link>
                    <Typography.Text> - Open a brand new contract and begin connecting</Typography.Text>
                </div>
                <div style={{ ...styles.cardItem, display: 'block' }}>
                    <Typography.Text style={{ display: 'table', margin: '0 auto' }}><b>Search Load Number</b> - enter the load number you’re looking for below</Typography.Text><br />
                    <Search placeholder="Search Load Number" onSearch={onSearchLoad} enterButton style={{ width: '50%', display: 'table', margin: '0 auto' }} type='number' loading={isFetchingLoads} />
                </div>
                <div style={{ ...styles.cardItem, display: 'block' }}>
                    <Typography.Text style={{ display: 'table', margin: '0 auto' }}><b>Search Contract Number</b> - enter the contract number you’re looking for below</Typography.Text><br />
                    <Search placeholder="Search Contract Number" onSearch={onSearchContract} enterButton style={{ width: '50%', display: 'table', margin: '0 auto' }} type='number' />
                </div>
            </Card >
            {/* <GraphicalReportingCard style={styles.dashboardCard} /> */}
        </div >
    );
};

export default Dashboard;
