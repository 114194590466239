import { Form, Input, DatePicker, Select, Row, InputNumber, Typography } from "antd";
import { useAuth } from "components/auth/authProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Company } from "routes/userManagement/types";
import { useContractsApi } from "./ContractDataProvider";

const layout = {
    labelCol: { span: 30 },
    wrapperCol: { span: 30 },
};

const { Option } = Select;
const { RangePicker } = DatePicker;
const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});
const formatPercent = (value: string | undefined) => {
    if (typeof value === "string" && !value.endsWith("%")) {
        return `${value}%`;
    }
    return value || "";
};
const selectAfter = (
    <Select defaultValue="CAD" style={{ width: 70 }}>
        <Option value="CAD">CAD</Option>
        <Option disabled value="USD">USD</Option>
        <Option disabled value="EUR">EUR</Option>
        <Option disabled value="GBP">GBP</Option>
    </Select>
);
const ContractForm = (props: any) => {

    const { id, submitForm, form, contract, setLoading, setFormChanged } = props;
    const { user } = useAuth();

    // UPDATING PRICING VALUES
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [amountValue, setAmountValue] = useState(0);
    const [taxTotalValue, setTaxTotalValue] = useState(0);
    const [totalAmountValue, setTotalAmountValue] = useState(0);
    const [userCompany, setUserCompany] = useState<Company>();
    const [isSeller, setIsSeller] = useState(true);
    const [disable, setDisable] = useState(false);

    // GET TAX RATES
    const {
        fetchTaxOptions, fetchCommodities, fetchCompanies,
        isFetchingTaxOptions, isFetchingCommodities, isFetchingCompanies,
        companies, commodities, taxOptions
    } = useContractsApi();

    useEffect(() => {
        if (taxOptions.length === 0 && !isFetchingTaxOptions) {
            fetchTaxOptions();
        }
        if (commodities.length === 0 && !isFetchingCommodities) {
            fetchCommodities();
        }
        if (Object.keys(companies).length === 0 && !isFetchingCompanies) {
            fetchCompanies();
        }
        // eslint-disable-next-line
    }, []);
    // SET SELLER COMPANY FROM USERCOMPANY
    useEffect(() => {
        setUserCompany(companies.filter((x) => x.name === user.company)[0]);
    }, [companies, user.company]);

    useEffect(() => {
        const values = form.getFieldsValue();
        values.seller_company = JSON.stringify(userCompany);
        form.setFieldsValue(values);
    }, [userCompany, form]);

    useEffect(() => {
        setAmountValue(price * quantity);
        setTaxTotalValue(amountValue * (taxAmount / 100));
        setTotalAmountValue(amountValue + taxTotalValue);
    }, [price, quantity, amountValue, taxAmount, taxTotalValue]);
    // GET CONTRACT IF FORM IS IN EDIT MODE
    useEffect(() => {
        if (id && contract) {
            // PRE-HANDLE DATA
            const values = form.getFieldsValue();
            // ASIGN STRING VALUES
            Object.assign(values, contract);

            // ASSIGN DATE, COMPANY AND TEXT VALUES FOR FORM
            values.purchase_date = moment(contract.purchase_date);
            values.date_range = [moment(contract.start_date), moment(contract.end_date)];
            values.purchaser_company = JSON.stringify(contract.purchaser_company);
            values.seller_company = JSON.stringify(contract.seller_company);
            setPrice(contract.price);
            setQuantity(contract.quantity);
            if (taxOptions.length > 0) {
                values.tax_type = taxOptions.find(x => x[0].toString() === contract.tax_type);
                setTaxAmount(values.tax_type[1]);
            }
            const fieldData: any[] = [];
            Object.keys(values).forEach(key => {
                const fd = { name: key, value: values[key], touched: false };
                fieldData.push(fd);
            });
            form.setFields(fieldData);
            setIsSeller(contract.seller_company.id === user.company_id);
            setLoading(false);
        } else if (!id) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, taxOptions, id, contract]);

    useEffect(() => {
        if (contract) {
            if (!['ACTIVE', 'SELLER_REQUESTED_EDIT'].includes(contract.status) || !isSeller) {
                setDisable(true);
            }
        }
    }, [contract, isSeller]);

    return (<Form
        form={form}
        labelCol={layout.labelCol}
        wrapperCol={layout.wrapperCol}
        name="create_contract_form"
        id="create_contract_form"
        initialValues={{ remember: true, }}
        onFinish={submitForm}
        onValuesChange={() => { setFormChanged(true); }}
    >
        <Form.Item
            label={<div className="input-label">Contract #</div>}
            name="id"
        >
            <Input placeholder="(Auto Populates)" disabled />
        </Form.Item>
        <Form.Item
            label={<div className="input-label">Contract Date</div>}
            rules={[
                { required: true, message: "Please specify the contract date" },
            ]}
            name="purchase_date"
        >
            <DatePicker style={{ width: "100%" }} disabled={disable} />
        </Form.Item>

        <Form.Item
            label={<div className="input-label">Seller</div>}
            name="seller_company"
        >
            <Select placeholder="Select Seller Company" disabled>
                {companies.map((company) => (
                    <Option key={company.name} value={JSON.stringify(company)}>
                        {company.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item
            label={<div className="input-label">Purchaser</div>}
            rules={[
                { required: true, message: "Please specify the purchaser company" },
            ]}
            name="purchaser_company"
        >
            <Select placeholder="Select Purchaser Company" disabled={disable || contract?.signature}>
                {companies.map((company) => (
                    <Option key={company.name} value={JSON.stringify(company)}>
                        {company.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>

        <Form.Item
            label={<div className="input-label">Commodity:</div>}
            rules={[{ required: true, message: "Please specify a commodity" }]}
            name="commodity"
        >
            <Select placeholder="Select Commodity" disabled={disable}>
                {commodities.map((commodity) => (
                    <Option key={commodity} value={commodity}>
                        {commodity}
                    </Option>
                ))}
            </Select>
        </Form.Item>

        <Form.Item
            label={<div className="input-label">Location</div>}
            rules={[{ required: true, message: "Please specify the location" }]}
            name="location"
        >
            <Input placeholder="Location" disabled={disable} />
        </Form.Item>
        <Form.Item
            label={<div className="input-label">Start and End Dates</div>}
            name="date_range"
            rules={[
                {
                    required: true,
                    message: "Please specify the start and end date of the contract",
                },
            ]}
        >
            <RangePicker style={{ width: "100%" }} disabled={disable} />
        </Form.Item>
        <Row>
            <Form.Item
                style={{ width: "50%" }}
                label={<div className="input-label">Quantity</div>}
                name="quantity"
            >
                <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Quantity"
                    min={0}
                    onChange={(value) => setQuantity(value ?? 0)}
                    addonAfter='t'
                    disabled={disable}
                />
            </Form.Item>
            <Form.Item
                style={{ paddingLeft: "1rem", width: "50%" }}
                label={<div className="input-label">Price</div>}
                name="price"
            >
                <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Price($/Metric Tonne)"
                    min={0}
                    precision={2}
                    onChange={(value) => setPrice(value ?? 0.0)}
                    addonAfter={selectAfter}
                    disabled={disable}
                />
            </Form.Item>
        </Row>
        <Row>
            <Form.Item
                style={{ width: "50%" }}
                name="tax_type"
                label={<div className="input-label">Tax</div>}
                rules={[{ required: true, message: "Select Tax" }]}
            >
                <Select
                    style={{ width: "100%" }}
                    placeholder="Select Tax"
                    onChange={(value: any) => setTaxAmount(JSON.parse(value)[1])}
                    disabled={disable}
                >
                    {taxOptions.map((taxOption) => (
                        <Option key={taxOption[0]} value={JSON.stringify(taxOption)}>
                            {taxOption[0]}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="tax_amount"
                style={{ paddingLeft: "1rem", width: "50%" }}
                label={<div className="input-label">Tax Percent</div>}
            >
                <Typography>{formatPercent(taxAmount.toString())}</Typography>
            </Form.Item>
        </Row>
        <Form.Item
            label={<div className="input-label">Value</div>}
            name="value"
        >
            <Typography>{formatCurrency.format(amountValue)}</Typography>
        </Form.Item>
        <Form.Item
            label={<div className="input-label">Tax Total</div>}
            name="tax_total"
        >
            <Typography>{formatCurrency.format(taxTotalValue)}</Typography>
        </Form.Item>
        <Form.Item
            label={<div className="input-label">Total Amount</div>}
            name="total_amount"
        >
            <Typography>{formatCurrency.format(totalAmountValue)}</Typography>
        </Form.Item>

        <Form.Item name="additional_terms">
            <Input.TextArea placeholder="Additional Terms" rows={3} disabled={disable} />
        </Form.Item>
    </Form>);
};
export default ContractForm;