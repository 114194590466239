import { useErrorsApi } from "components/layouts/ErrorMessageProvider";
import React, { useState, useContext, createContext } from "react";
import { postData, getData, deleteData, updateWithFormData, postWithFormData } from "utils/network";
import { User } from "./types";

const accountsApi = "/api/accounts/";
const companiesApi = "/api/companies/";
const websiteMessagesApi = "/api/website-messages/";
const feedbackApi = "/api/feedback/";

interface AuthInterface {
  user: any;
  login: (
    email: string,
    password: string,
    remember: boolean,
    onSuccess?: () => void
  ) => void;
  loginErrors?: string;
  isLoggedIn: boolean;
  logout: (onLogout: () => void) => void;
  isAuthorizing: boolean;
  getUser: ({
    onSuccess,
    onFail,
  }: {
    onSuccess?: () => void;
    onFail?: () => void;
  }) => void;
  isFetchingUser: boolean;

  sendFeedback: (datas: {}, onSuccess?: () => void, onFail?: () => void) => void,
  isSendingFeedback: boolean

  websiteMessages: string[],
  fetchWebsiteMessages: (onSuccess?: () => void, onFail?: () => void) => void,
  isFetchingWebsiteMessages: boolean,
  updateWebsiteMessageDismissed: (id: number, onSuccess?: () => void, onFail?: () => void) => void,
  isUpdatingWebsiteMessageDismissed: boolean,

  deleteUser: (id: number, onSuccess?: () => void, onFail?: () => void) => void;
  isDeletingUser: boolean;

  updateUser: (
    id: number,
    datas: FormData,
    onSuccess?: () => void,
    updatingCompanyUser?: boolean
  ) => void;
  isUpdatingUser: boolean;

  users: User[];
  getUsers: (onSuccess?: () => void, onFail?: () => void) => void;
  isFetchingUsers: boolean;

  createUser: (datas: FormData, onSuccess?: () => void) => void;
  isCreatingUser: boolean;

  company: any;
  getCompany: (onSuccess?: () => void) => void;
  isFetchingCompany: boolean;
  updateCompany: (id: number, datas: FormData, onSuccess?: () => void) => void;
  isUpdatingCompany: boolean;
}

function useProvideAuth() {
  const { addError } = useErrorsApi();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [websiteMessages, setWebsiteMessages] = useState([]);
  const [isFetchingWebsiteMessages, setIsFetchingWebsiteMessages] = useState(false);
  const [isUpdatingWebsiteMessageDismissed, setIsUpdatingWebsiteMessageDismissed] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  const [company, setCompany] = useState(null);
  const [isFetchingCompany, setIsFetchingCompany] = useState(false);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);

  const resetStates = () => {
    setUser(null);
    setUsers([]);
    setIsFetchingUsers(false);
    setLoginErrors("");
    setIsLoggedIn(false);
    setIsAuthorizing(false);
    setIsFetchingUser(false);
    setIsUpdatingUser(false);
    setIsCreatingUser(false);
    setWebsiteMessages([]);
    setIsFetchingWebsiteMessages(false);
    setIsUpdatingWebsiteMessageDismissed(false);
    setIsDeletingUser(false);
    setIsSendingFeedback(false);
    setCompany(null);
    setIsFetchingCompany(false);
    setIsUpdatingCompany(false);
  };

  const getUser: AuthInterface["getUser"] = ({ onSuccess, onFail }) => {
    // TODO: see if we can use either session auth or token auth here
    if (!user) {
      const userRequest = getData(`${accountsApi}me/`);
      setIsFetchingUser(true);
      userRequest.then((response) => {
        try {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((data) => {
              setUser(data);
              if (onSuccess) {
                onSuccess();
              }
            });
          } else if (onFail) {
            addError(`Error fetching user: ${response.statusText}`);
            onFail();
          }
        } catch {
          if (onFail) {
            addError(`Error fetching user`);
            onFail();
          }
        }

        setIsFetchingUser(false);
      });
    }
  };
  const login: AuthInterface["login"] = (
    email,
    password,
    remember,
    onSuccess
  ) => {
    if (email && password) {
      setIsAuthorizing(true);
      const request = postData(`${accountsApi}login/`, {
        email,
        password,
        username: email,
      });
      request.then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (loginErrors) {
            setLoginErrors("");
          }
          response.json().then((json) => {
            const token = json.key;
            if (remember) {
              localStorage.setItem("token", token);
            }
            getUser({ onSuccess });
            setIsLoggedIn(true);
          });
        } else if (response.status >= 400 && response.status < 500) {
          setLoginErrors("Could not login with provided credentials");
        } else if (response.status >= 500) {
          setLoginErrors("Server issues. Please try again later");
        }
        setIsAuthorizing(false);
      });
    }
  };

  // FETCH WEBSITE MESSAGES
  const fetchWebsiteMessages = (onSuccess?: () => void, onFail?: () => void) => {
    const fetchWebsiteMessagesApi = `${websiteMessagesApi}`;
    const fetchWebsiteMessagesRequest = getData(`${fetchWebsiteMessagesApi}`);

    setIsFetchingWebsiteMessages(true);

    fetchWebsiteMessagesRequest.then(response => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then(data => {
          setWebsiteMessages(data);
          setIsFetchingWebsiteMessages(false);
        });
      } else {
        if (onFail) {
          onFail();
        }
        addError(`Error fetching website messages: ${response.status}`);
        setIsFetchingWebsiteMessages(false);
      }
    });
  };
  // UPDATE WEBSITE MESSAGE DISMISSED
  const updateWebsiteMessageDismissed = (id: number, onSuccess?: () => void, onFail?: () => void) => {
    const updateWebsiteMessageDismissedApi = `${websiteMessagesApi}${id}/dismiss/`;
    const updateWebsiteMessageRequest = postData(`${updateWebsiteMessageDismissedApi}`);

    setIsUpdatingWebsiteMessageDismissed(true);

    updateWebsiteMessageRequest.then(response => {
      if (response.status >= 200 && response.status < 300) {
        if (onSuccess) {
          onSuccess();
        }
        setIsUpdatingWebsiteMessageDismissed(false);
      } else {
        if (onFail) {
          onFail();
        }
        addError(`Error dismissing website message: ${response.status}`);
        setIsUpdatingWebsiteMessageDismissed(false);
      }
    });
  };
  // DELETE LOAD
  const deleteUser = (
    id: number,
    onSuccess?: () => void,
    onFail?: () => void
  ) => {
    const deleteUserApi = `${accountsApi}users/${id}`;
    setIsDeletingUser(true);
    deleteData(deleteUserApi)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const found = users.find((x) => x.id === id);
          users.splice(users.indexOf(found as User), 1);
          setUsers([...users]);
        } else {
          if (onFail) {
            onFail();
          }
          addError(`Error deleting user: ${response.statusText}`);
          setIsDeletingUser(false);
        }
      })
      .finally(() => {
        if (onSuccess) {
          onSuccess();
        }
        setIsDeletingUser(false);
      });
  };

  const createUser = (datas: FormData, onSuccess?: () => void) => {
    const createUserApi = `${accountsApi}users/`;
    const request = postWithFormData(createUserApi, datas);
    setIsCreatingUser(true);

    request
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            setUsers([...users, data]);
          });
        } else {
          addError(`Error creating user: ${response.statusText}`);
          setIsCreatingUser(false);
        }
      })
      .finally(() => {
        if (onSuccess) {
          onSuccess();
        }
        setIsCreatingUser(false);
      });
  };

  const updateUser = (
    id: number,
    datas: FormData,
    onSuccess?: () => void,
    updatingCompanyUser = false
  ) => {
    const updateUserApi = `${accountsApi}users/`;
    const request = updateWithFormData(`${updateUserApi}${id}`, datas);
    setIsUpdatingUser(true);
    request
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            if (updatingCompanyUser) {
              const userToUpdate = users.find((x) => x.id === id);
              users.splice(users.indexOf(userToUpdate as User), 1);
              setUsers([...users, data]);
            } else {
              setUser(data);
            }
          });
        } else {
          addError(`Error updating user: ${response.statusText}`);
          setIsUpdatingUser(false);
        }
      })
      .finally(() => {
        if (onSuccess) {
          onSuccess();
        }
        setIsUpdatingUser(false);
      });
  };

  const logout = (onLogout?: () => void) => {
    const request = postData(`${accountsApi}logout/`);
    setIsAuthorizing(true);
    request.then((response) => {
      if (response.status >= 200 && response.status < 300) {
        localStorage.removeItem("token");
        resetStates();
        if (onLogout) {
          onLogout();
        }
      }
      setIsAuthorizing(false);
    });
  };

  const getUsers = (onSuccess?: () => void, onFail?: () => void) => {
    const getUsersApi = `${accountsApi}users/`;
    const request = getData(`${getUsersApi}`);
    setIsFetchingUsers(true);
    request.then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          if (onSuccess) {
            onSuccess();
          }
          setUsers(data);
          setIsFetchingUsers(false);
        });
      } else {
        if (onFail) {
          onFail();
        }
        addError(`Error fetching users: ${response.statusText}`);
        setIsFetchingUsers(false);
      }
    });
  };

  const sendFeedback = (datas: {}, onSuccess?: () => void, onFail?: () => void) => {
    const sendFeedbackApi = `${feedbackApi}`;
    setIsSendingFeedback(true);
    const sendFeedbackRequest = postData(`${sendFeedbackApi}`, datas);
    sendFeedbackRequest.then((response) => {
      if (response.status >= 200 && response.status < 300) {
        if (onSuccess) {
          onSuccess();
        }
        setIsSendingFeedback(false);
      } else {
        if (onFail) {
          onFail();
        }
        addError(`Error sending feedback: ${response.statusText}`);
        setIsSendingFeedback(false);
      }
    });
  };

  const getCompany = (onSuccess?: () => void) => {
    const getCompanyApi = `${companiesApi}company/`;
    const getCompanyRequest = getData(`${getCompanyApi}`);
    setIsFetchingCompany(true);
    getCompanyRequest.then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          setCompany(data);
          if (onSuccess) {
            onSuccess();
          }
          setIsFetchingCompany(false);
        });
      } else {
        addError(`Error fetching company: ${response.statusText}`);
        setIsFetchingCompany(false);
      }
    });
  };

  const updateCompany = (id: number, datas: FormData, onSuccess?: () => void) => {
    const updateCompanyApi = `${companiesApi}company/${id}`;
    setIsUpdatingCompany(true);
    const updateCompanyRequest = updateWithFormData(updateCompanyApi, datas);
    updateCompanyRequest.then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          setCompany(data);
        });
      } else {
        addError(`Error updating company: ${response.statusText}`);
        setIsUpdatingCompany(false);
      }
    }).finally(() => {
      if (onSuccess) {
        onSuccess();
      }
      setIsUpdatingCompany(false);
    });
  };

  // Return the user object and auth methods
  return {
    user,
    login,
    isLoggedIn,
    loginErrors,
    logout,
    isAuthorizing,
    getUser,
    isFetchingUser,
    sendFeedback,
    isSendingFeedback,
    websiteMessages,
    fetchWebsiteMessages,
    isFetchingWebsiteMessages,
    updateWebsiteMessageDismissed,
    isUpdatingWebsiteMessageDismissed,
    deleteUser,
    isDeletingUser,
    updateUser,
    isUpdatingUser,
    users,
    getUsers,
    isFetchingUsers,
    createUser,
    isCreatingUser,
    company,
    getCompany,
    isFetchingCompany,
    updateCompany,
    isUpdatingCompany,
  };
}

const authContext = createContext<AuthInterface>({
  user: null,
  login: () => { },
  isLoggedIn: false,
  isAuthorizing: false,
  logout: () => { },
  getUser: () => { },
  isFetchingUser: false,
  sendFeedback: () => { },
  isSendingFeedback: false,
  websiteMessages: [],
  fetchWebsiteMessages: () => { },
  isFetchingWebsiteMessages: false,
  updateWebsiteMessageDismissed: () => { },
  isUpdatingWebsiteMessageDismissed: false,
  deleteUser: () => { },
  isDeletingUser: false,
  updateUser: () => { },
  isUpdatingUser: false,
  users: [],
  getUsers: () => { },
  isFetchingUsers: false,
  createUser: () => { },
  isCreatingUser: false,
  company: null,
  getCompany: () => { },
  isFetchingCompany: false,
  updateCompany: () => { },
  isUpdatingCompany: false,
});

export default function ProvideAuth({
  children,
}: {
  children: React.ReactNode;
}) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  const auth = useContext(authContext);
  return auth;
};
