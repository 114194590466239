/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react';
import { Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { useAuth } from 'components/auth/authProvider';
import { Tabs, Button, Row, Badge, Typography, Col, Card, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SearchAndFilterContracts from 'utils/SearchAndFilterContracts';
import CreateContract from './CreateContract';
import ContractsTable from './table/ContractsTable';
import './contracts.css';
import { useContractsApi } from './ContractDataProvider';

const styles = {
    mainCard: {
        // width: '80%',
        margin: 'auto',
        height: 'fit-content',
        // border: '1px solid #3BB42A',
        boxShadow: '0px 0px 5px 1px #4daa48'
    },
} as { [key: string]: React.CSSProperties };

const Actions = () => {
    const { action, contractId, selectedTab, loadId }: { action: string, contractId: string, selectedTab: string, loadId: string } = useParams();
    const st = selectedTab ?? 'contract';
    switch (action) {
        case 'edit':
            return <CreateContract contractId={contractId} selectedTab={st} loadId={loadId} />;
        case 'create':
        default:
            return <CreateContract selectedTab={st} />;
    }
};

const CreateContractBtn = () => {
    const { push } = useHistory();
    return <Button size='large' style={{ width: '100%' }} type='primary' icon={<PlusOutlined />} onClick={() => push('/contracts/create')}>Create Contract</Button>;
};
const Contracts = () => {
    const { user, isFetchingUser } = useAuth();
    const [searchValue, setSearchValue] = useState<string[]>([]);
    const [contractParams, setContractParams] = useState<Record<string, any>>({});
    const [activeTab, setActiveTab] = useState('Open Contracts');
    const [resetTable, setResetTable] = useState(false);
    const {
        contracts,
        totalCount,
        isFetchingContracts,
        isFetchingCommodities,
        isFetchingCompanies,
        principleQuantity,
        remainingQuantity,
        commodities,
        companies,
        isDownloadingContractPDF,
        contractNotifications,
        notificationCount,
        isFetchingContractNotifications,
        requestRefetch,
        setRequestRefetch,
        fetchContracts,
        fetchCommodities,
        fetchCompanies,
        deleteContract,
        completeContract,
        cancelContract,
        downloadContractPDF,
        fetchContractNotifications,
    } = useContractsApi();

    const getDefaultParams = () => {
        const params: Record<string, any> = { limit: 10, offset: 0 };
        params.ordering = '-id';
        if (activeTab === 'Open Contracts') {
            params.closed = false;
        } else {
            params.closed = true;
        }
        return params;
    };

    useEffect(() => {
        if (totalCount < 0) {
            const params = getDefaultParams();
            setContractParams(params);
        }
        // eslint-disable-next-line
    }, [contracts, totalCount]);

    // GET TAX RATES
    useEffect(() => {
        if (commodities.length === 0 && !isFetchingCommodities) {
            fetchCommodities();
        }
        if (Object.keys(companies).length === 0 && !isFetchingCompanies) {
            fetchCompanies();
        }
        if (notificationCount < 0 && !isFetchingContractNotifications) {
            fetchContractNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies, commodities]);

    useEffect(() => {
        const params = getDefaultParams();
        setContractParams({ ...contractParams, ...params });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        if (Object.keys(contractParams).length > 0) {
            fetchContracts(undefined, contractParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractParams]);

    useEffect(() => {
        if (requestRefetch && !isFetchingContracts && Object.keys(contractParams).length > 0) {
            fetchContracts(undefined, contractParams, () => {
                setRequestRefetch(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestRefetch, contractParams]);

    const tablePropsChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        const params: Record<string, any> = { limit: pagination.pageSize, offset: ((pagination.current - 1) * pagination.pageSize) };
        if (extra.action === 'sort') {
            if (sorter.order) {
                params.ordering = `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`;
            }
        }
        if (activeTab === 'Open Contracts') {
            params.closed = false;
        } else {
            params.closed = true;
        }

        Object.keys(contractParams)
            .filter(key => ['search', 'commodity', 'partner'].includes(key))
            .forEach(key => {
                params[key] = contractParams[key];
            });
        setContractParams(params);
        // fetchContracts(undefined, params);
    };

    const data = useMemo(() => Object.values(contracts), [contracts]);

    const onSearch = (searchText: string, searchCommodities: string, searchPartners: string) => {
        const params: Record<string, any> = {};
        if (resetTable) {
            setSearchValue([searchText, searchCommodities, searchPartners]);
            setContractParams(getDefaultParams());
            setResetTable(false);
            return;
        }
        if (searchText) {
            params.search = searchText;
        } else if (contractParams.search) {
            delete contractParams.search;
        }
        if (searchCommodities) {
            params.commodity = searchCommodities;
        } else if (contractParams.commodity) {
            delete contractParams.commodity;
        }
        if (searchPartners) {
            params.partner = searchPartners;
        } else if (contractParams.commodity) {
            delete contractParams.partner;
        }
        setSearchValue([searchText, searchCommodities, searchPartners]);
        setContractParams({ ...contractParams, ...params });
    };

    const { path } = useRouteMatch();
    return (
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Card style={styles.mainCard} size="default">
                <div className='contracts-content'>
                    {!isFetchingUser &&
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Badge offset={[15, 10]} showZero count={contractNotifications.filter(x => x.notifications.some(n => n === 'SIGNATURE_REQUIRED')).length}>
                                    <Typography.Title level={5}>Needs Signing</Typography.Title>
                                </Badge>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Badge offset={[15, 10]} showZero count={contractNotifications.filter(x => x.notifications.some(n => n === 'SIGNATURE_PENDING')).length}>
                                    <Typography.Title level={5}>Pending Signature</Typography.Title>
                                </Badge>
                            </Col>
                            <Col xs={24} sm={24} md={{ offset: 8, span: 8 }} lg={{ offset: 8, span: 8 }} xl={{ offset: 8, span: 8 }}>
                                <CreateContractBtn />
                            </Col>
                        </Row>
                    }
                    <SearchAndFilterContracts onSearch={onSearch} companies={companies} commodities={commodities} resetTable={resetTable} setResetTable={setResetTable} />

                    <Tabs style={{ height: '100%' }} onChange={setActiveTab} type='card' activeKey={activeTab}>
                        <Tabs.TabPane style={{ height: '100%' }} tab='Open Contracts' key='Open Contracts'>
                            <ContractsTable
                                data={data}
                                user={user}
                                searchValue={searchValue}
                                deleteContract={deleteContract}
                                completeContract={completeContract}
                                cancelContract={cancelContract}
                                downloadContractPDF={downloadContractPDF}
                                isDownloadingContractPDF={isDownloadingContractPDF}
                                tablePropsChange={tablePropsChange}
                                totalCount={totalCount}
                                principleQuantity={principleQuantity}
                                remainingQuantity={remainingQuantity}
                                isFetchingContracts={isFetchingContracts}
                                contractNotifications={contractNotifications} />
                        </Tabs.TabPane>
                        <Tabs.TabPane style={{ height: '100%' }} tab='Closed Contracts' key='Closed Contracts'>
                            <ContractsTable
                                data={data}
                                user={user}
                                searchValue={searchValue}
                                deleteContract={deleteContract}
                                tablePropsChange={tablePropsChange}
                                totalCount={totalCount}
                                principleQuantity={principleQuantity}
                                remainingQuantity={remainingQuantity}
                                isFetchingContracts={isFetchingContracts} />
                        </Tabs.TabPane>
                    </Tabs>

                    <Route path={`${path}/:action`} exact component={Actions} />
                    <Route path={`${path}/:action/:contractId`} exact component={Actions} />
                    <Route path={`${path}/:action/:contractId/:selectedTab`} exact component={Actions} />
                    <Route path={`${path}/:action/:contractId/:selectedTab/:loadId`} exact component={Actions} />
                </div>
            </Card>
        </Space>
    );
};

export default Contracts;
