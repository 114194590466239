import { useErrorsApi } from 'components/layouts/ErrorMessageProvider';
import React, { createContext, useContext, useState } from 'react';

import { deleteData, getData, postData, postWithFormData, updateData } from 'utils/network';
// Types
import Load, { Attachment } from './types';

const loadsApi = '/api/loads/';

// type LoadsById = { [id: string]: Load }
interface LoadsAPIInterface {
    // FETCH LOADS
    loads: Load[]
    clearLoads: () => void
    fetchLoads: (id?: number, onSuccess?: (load: Load) => void, onError?: () => void) => void
    isFetchingLoads: boolean,

    // FETCH LOADS BY CONTRACT ID
    fetchLoadsByContractId: (id: number, onSuccess?: (loadsByContractId: Load[]) => void) => void
    isFetchingLoadsByContractId: boolean,

    // POST LOADS
    postLoad: (datas: {}, onSuccess?: () => void) => void,
    isPostingLoad: boolean,

    // UPDATE LOADS
    updateLoad: (datas: {}, id: number, onSuccess?: () => void) => void,
    isUpdatingLoad: boolean,

    // DELTE LOADS
    deleteLoad: (id: number, onSuccess?: () => void) => void,
    isDeletingLoad: boolean,

    // FETCH LOAD ATTACHMENTS
    fetchLoadAttachments: (id: number, onSuccess?: (attachments: Attachment[]) => void) => void,
    isFetchingLoadAttachments: boolean,

    // POST ATTACHMENT
    postLoadAttachment: (id: number, datas: FormData, onSuccess?: (attachment: Attachment) => void) => void,
    isPostingLoadAttachment: boolean,

    // DELETE LOAD ATTACHMENT
    deleteLoadAttachment: (lid: number, aid: number, onSuccess?: () => void) => void,
    isDeletingLoadAttachment: boolean,

    // DOWNLOAD ATTACHMENT
    downloadAttachment: (url: string, onSuccess?: () => void) => void,
    isDownloadingAttachment: boolean,

    setEditLoad: (load?: Load) => void;
    loadInEditMode: {};

    setLoadsOnContractUpdate: (updatedLoads: Load[]) => void;
}

const decodeLoadResponse = (data: Load[] | Load) => {
    if (Array.isArray(data)) {
        return data;
    }
    if (data) {
        return [data];
    }
    return [];
};

function useProvideLoadsApi(): LoadsAPIInterface {
    const { addError } = useErrorsApi();

    const [loads, setLoads] = useState<Load[]>([]);
    const [isFetchingLoads, setIsFetchingLoads] = useState(false);
    const [isFetchingLoadsByContractId, setIsFetchingLoadsByContractId] = useState(false);
    const [loadInEditMode, setLoadInEditMode] = useState({});

    const clearLoads = () => {
        setLoads([]);
    };

    // FETCH LOADS
    const fetchLoads = (id?: number, onSuccess?: (load: Load) => void, onError?: () => void) => {
        const getLoadRequest = getData(`${loadsApi}${id ? `${id}` : ''}`);
        setIsFetchingLoads(true);
        getLoadRequest.then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeLoadResponse(data);
                    if (onSuccess && id) {
                        onSuccess(decodedData[0]);
                    }
                    setLoads([...loads, ...decodedData]);
                    setIsFetchingLoads(false);
                });
            } else {
                if (onError) {
                    onError();
                }
                addError(`Error fetching loads: ${response.statusText}`);
                setIsFetchingLoads(false);
            }

        });
    };

    const fetchLoadsByContractId = (id: number, onSuccess?: (loadsByContractId: Load[]) => void) => {
        const params = { contract: id };
        const getLoadRequest = getData(`${loadsApi}`, params);
        setIsFetchingLoadsByContractId(true);
        getLoadRequest.then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeLoadResponse(data);
                    if (onSuccess) {
                        onSuccess(decodedData);
                    }
                    setLoads([...decodedData]);
                    setIsFetchingLoadsByContractId(false);
                });
            } else {
                addError(`Error fetching loads: ${response.statusText}`);
                setIsFetchingLoadsByContractId(false);
            }
        });
    };

    // POST LOAD
    const [isPostingLoad, setIsPostingLoad] = useState(false);
    const postLoad = (datas: {}, onSuccess?: () => void) => {
        setIsPostingLoad(true);
        postData(loadsApi, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeLoadResponse(data);
                    if (onSuccess) {
                        onSuccess();
                    }
                    setLoads([...loads, ...decodedData]);

                    setIsPostingLoad(false);
                });
            } else {
                addError(`Error saving load: ${response.statusText}`);
                setIsPostingLoad(false);
            }
        });
    };

    // UPDATE LOAD
    const [isUpdatingLoad, setIsUpdatingLoad] = useState(false);
    const updateLoad = (datas: {}, id: number, onSuccess?: () => void) => {
        const updateLoadApi = `${loadsApi}${id}/`;
        setIsUpdatingLoad(true);
        updateData(updateLoadApi, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(data => {
                    const decodedData = decodeLoadResponse(data);
                    if (onSuccess) {
                        onSuccess();
                    }
                    loads[loads.findIndex(x => +x.id === +id)] = decodedData[decodedData.findIndex(x => +x.id === +id)];
                    setLoads([...loads]);
                    setIsUpdatingLoad(false);
                });
            } else {
                addError(`Error updating load: ${response.statusText}`);
                setIsUpdatingLoad(false);
            }

        });
    };

    // DELETE LOAD
    const [isDeletingLoad, setIsDeletingLoad] = useState(false);
    const deleteLoad = (id: number, onSuccess?: () => void) => {
        const deleteLoadApi = `${loadsApi}${id}/`;
        setIsDeletingLoad(true);
        deleteData(deleteLoadApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                const found = loads.find(x => x.id === id);
                loads.splice(loads.indexOf(found as Load), 1);
                setLoads([...loads]);
                if (onSuccess) {
                    onSuccess();
                }
                setIsDeletingLoad(false);
            } else {
                addError(`Error deleting load: ${response.statusText}`);
                setIsDeletingLoad(false);
            }
        });
    };


    // FETCH LOAD ATTACHMENTS
    const [isFetchingLoadAttachments, setIsFetchingLoadAttachments] = useState(false);
    const fetchLoadAttachments = (id: number, onSuccess?: (attachments: Attachment[]) => void) => {
        const fetchLoadAttachmentsApi = `${loadsApi}${id}/attachments/`;
        setIsFetchingLoadAttachments(true);
        getData(fetchLoadAttachmentsApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data) => {
                    if (onSuccess) {
                        onSuccess(data);
                    }
                });
            } else {
                setIsFetchingLoadAttachments(false);
                addError(`Error fetching attachments: ${response.statusText}`);
            }
        })
            .finally(() => {
                setIsFetchingLoadAttachments(false);
            });
    };
    // POST LOAD ATTACHMENT
    const [isPostingLoadAttachment, setIsPostingLoadAttachment] = useState(false);
    const postLoadAttachment = (id: number, datas: FormData, onSuccess?: (attachment: Attachment) => void) => {
        const postLoadAttachmentApi = `${loadsApi}${id}/attachments/`;
        setIsPostingLoadAttachment(true);
        postWithFormData(postLoadAttachmentApi, datas).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((data) => {
                    if (onSuccess) {
                        onSuccess(data);
                    }
                });
            } else {
                setIsPostingLoadAttachment(false);
                addError(`Error saving attachment: ${response.statusText}`);
            }
        })
            .finally(() => {
                setIsPostingLoadAttachment(false);
            });
    };

    // DELETE LOAD ATTACHMENTS
    const [isDeletingLoadAttachment, setIsDeletingLoadAttachment] = useState(false);
    const deleteLoadAttachment = (lid: number, aid: number, onSuccess?: () => void) => {
        const deleteLoadAttachmentApi = `${loadsApi}${lid}/attachments/${aid}/`;
        setIsDeletingLoadAttachment(true);
        deleteData(deleteLoadAttachmentApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                setIsDeletingLoadAttachment(false);
                addError(`Error deleting attachment: ${response.statusText}`);
            }
        }).finally(() => {
            setIsDeletingLoadAttachment(false);
        });
    };

    const [isDownloadingAttachment, setIsDownloadingAttachment] = useState(false);
    const downloadAttachment = (url: string, onSuccess?: () => void) => {
        const downloadAttachmentApi = `${url}`;
        setIsDownloadingAttachment(true);
        getData(downloadAttachmentApi).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.blob()
                    .then(data => window.open(URL.createObjectURL(data)));
                if (onSuccess) {
                    onSuccess();
                }
                setIsDownloadingAttachment(false);
            } else {
                addError(`Error downloading attachment: ${response.statusText}`);
                setIsDownloadingAttachment(false);
            }
        });
    };

    const setEditLoad = (load?: Load) => {
        setLoadInEditMode(load ?? {});
    };

    const setLoadsOnContractUpdate = (updatedLoads: Load[]) => {
        setLoads(updatedLoads);
    };

    return {
        // FETCH LOADS
        loads,
        clearLoads,
        fetchLoads,
        isFetchingLoads,

        // FETCH LOADS BY CONTRACTID
        fetchLoadsByContractId,
        isFetchingLoadsByContractId,

        // POST LOAD
        postLoad,
        isPostingLoad,

        // UPDATE LOAD
        updateLoad,
        isUpdatingLoad,

        // DELETE LOAD
        deleteLoad,
        isDeletingLoad,

        // FETCH LOAD ATTACHMENTS
        fetchLoadAttachments,
        isFetchingLoadAttachments,

        // POST ATTACHMENT
        postLoadAttachment,
        isPostingLoadAttachment,

        // DELETE LOAD ATTACHMENTS
        deleteLoadAttachment,
        isDeletingLoadAttachment,

        // DOWNLOAD ATTACHMENT
        downloadAttachment,
        isDownloadingAttachment,

        setEditLoad,
        loadInEditMode,

        setLoadsOnContractUpdate
    };
}

const LoadsContext = createContext<LoadsAPIInterface>({
    // FETCH LOADS
    loads: [],
    clearLoads: () => { },
    fetchLoads: () => { },
    isFetchingLoads: false,

    // FETCH LOADS BY CONTRACTID
    fetchLoadsByContractId: () => { },
    isFetchingLoadsByContractId: false,

    // POST LOAD
    postLoad: () => { },
    isPostingLoad: false,

    // UPDATE LOAD
    updateLoad: () => { },
    isUpdatingLoad: false,

    // DELETE LOAD
    deleteLoad: () => { },
    isDeletingLoad: false,

    // FETCH LOAD ATTACHMENTS
    fetchLoadAttachments: () => { },
    isFetchingLoadAttachments: false,

    // POST ATTACHMENT
    postLoadAttachment: () => { },
    isPostingLoadAttachment: false,

    // DELETE LOAD ATTACHMENT
    deleteLoadAttachment: () => { },
    isDeletingLoadAttachment: false,

    // DOWNLOAD ATTACHMENT
    downloadAttachment: () => { },
    isDownloadingAttachment: false,

    setEditLoad: () => { },
    loadInEditMode: {},

    setLoadsOnContractUpdate: () => { },
});
export default function ProvideLoadsContext({ children }: { children: React.ReactNode }) {
    const auth = useProvideLoadsApi();
    return <LoadsContext.Provider value={auth}>{children}</LoadsContext.Provider>;
};

export const useLoadsApi = () => {
    const LoadsContextApi = useContext(LoadsContext);
    return LoadsContextApi;
};
