/* eslint-disable @typescript-eslint/no-unused-vars */

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useState } from 'react';

const UploadProfilePicture = (props: any) => {
    const { setFile, isProfilePic } = props;
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <div className='image-uploader'>
            {
                // isProfilePic ?
                <ImgCrop>
                    < Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        beforeUpload={(file, filelist) => { setFile(file); return false; }}
                        onPreview={onPreview}
                        maxCount={1}
                        style={{ width: '100%' }}
                    >
                        {fileList.length < 1 && '+ Upload'}
                    </Upload >
                </ImgCrop >
                // :
                // <Upload
                //     listType="picture-card"
                //     fileList={fileList}
                //     onChange={onChange}
                //     beforeUpload={(file, filelist) => { setFile(file); return false; }}
                //     onPreview={onPreview}
                //     maxCount={1}
                //     style={{ width: '100%' }}
                // >
                //     {fileList.length < 1 && '+ Upload'}
                // </Upload>
            }
        </div>
    );
};

export default UploadProfilePicture;