import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Space, Spin, Table, TableProps } from "antd";
import { User } from "components/auth/types";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import tableColumns from './columns';

const styles = {
    loadingSpinner: {
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        alignItems: 'center'
    },
};
const UsersCard = ({ users, user }: any) => {

    const columns = tableColumns();
    // FILTER ON CONTRACT ID TEMPORARY
    const data: User[] = useMemo(() =>
        Object.values<User>(users).filter(u => u.id !== user.id),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [users]);
    const onChange: TableProps<User>['onChange'] = () => { };

    const GetAddUsersButton = () => {
        const { push } = useHistory();
        return <Button type="primary" icon={<PlusOutlined />} onClick={() => push('/profile/users/create')} style={{ float: 'right', marginBottom: '1rem' }}>Create User</Button>;
    };
    return (
        <div className="users-card">
            {!users ? <Spin style={styles.loadingSpinner} /> :
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>

                    <Card title="Users" hoverable size="default">
                        {GetAddUsersButton()}
                        <Table
                            size="middle"
                            pagination={{ pageSize: 4 }}
                            columns={columns}
                            dataSource={data}
                            onChange={onChange}
                            scroll={{ x: 300 }}
                            className="users-table"
                            tableLayout='fixed' />
                    </Card>
                </Space>
            }
        </div>
    );
};

export default UsersCard;