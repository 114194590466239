/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { isObject } from "util";
import { useContractsApi } from "./ContractDataProvider";
import { Contract } from "./types";

const ContractStats = (props: any) => {
    const { contracts, requestRefetch } = useContractsApi();
    const { icon, title, contractId } = props;
    const [contract, setContract] = useState({});

    const [loadCount, setLoadCount] = useState<number>();
    const [scaleTicketCount, setScaleTicketCount] = useState<number>();
    const [quantityReceived, setQuantityReceived] = useState<number>();
    const [dollarAmount, setDollarAmount] = useState<number>();
    const [quantityRemaining, setQuantityRemaining] = useState<number>();

    useEffect(() => {
        if (contract) {
            setLoadCount((contract as Contract).load_count);
            setScaleTicketCount((contract as Contract).scale_ticket_count);
            setQuantityReceived((contract as Contract).quantity_received);
            setDollarAmount((contract as Contract).dollar_amount);
            setQuantityRemaining((contract as Contract).quantity_remaining);
        }
    }, [contract]);

    useEffect(() => {
        if (!requestRefetch) {
            setContract({});
        }
    }, [requestRefetch]);

    useEffect(() => {
        if (contract && Object.keys(contracts).length > 0 && Object.keys(contract).length === 0) {
            setContract(contracts[contractId]);
        }
    }, [contracts, contractId, contract]);
    return (
        <Row>
            {/* <Col span={8} /> */}
            <Col span={8} offset={8} style={{ textAlign: 'center', marginTop: 'auto', marginBottom: 'auto' }}>
                <Typography.Title style={{}}>{icon} {title}</Typography.Title>
            </Col>
            <Col span={8} style={{ marginTop: 'auto', marginBottom: 'auto' }}>

                <Typography.Text style={{ float: 'right' }}>{loadCount} Load(s)</Typography.Text><br />
                <Typography.Text style={{ float: 'right' }}>{scaleTicketCount} Ticket(s)</Typography.Text><br />
                <Typography.Text style={{ float: 'right' }}>{`${new Intl.NumberFormat('en-US').format(quantityReceived ?? 0)}`} Quantity Received</Typography.Text><br />
                <Typography.Text style={{ float: 'right' }}>{`$${new Intl.NumberFormat('en-US').format(dollarAmount ?? 0)}`} Dollar Value</Typography.Text><br />
                <Typography.Text style={{ float: 'right' }}>{`${new Intl.NumberFormat('en-US').format(quantityRemaining ?? 0)}`} Quantity Remaining</Typography.Text>
            </Col>
        </Row>
    );
};

export default ContractStats;
