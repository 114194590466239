export const PROP_ID = 'id';
export const PROP_ADDITIONAL_TERMS = 'additional_terms';
export const PROP_CANCELLED = 'cancelled';
export const PROP_COMMODITY = 'commodity';
export const PROP_COMPLETED = 'completed';
export const PROP_END_DATE = 'end_date';
export const PROP_LOCATION = 'location';
export const PROP_PRICE = 'price';
export const PROP_PURCHASE_DATE = 'purchase_date';
export const PROP_PURCHASER_REQUESTED_CANCELLATION = 'purchaser_requested_cancellation';
export const PROP_PRINCIPAL_QUANTITY = 'quantity';
export const PROP_QUANTITY_REMAINING = 'quantity_remaining';
export const PROP_SELLER_REQUESTED_CANCELLATION = 'seller_requested_cancellation';
export const PROP_SIGNATURE = 'signature';
export const PROP_START_DATE = 'start_date';
export const PROP_TAX_TYPE = 'tax_type';
export const PROP_SELLER_COMPANY = 'seller_company';
export const PROP_PURCHASER_COMPANY = 'purchaser_company';

// export const TAX_OPTIONS = [
//     { label: 'EXEMPT', value: 0 },
//     { label: 'GST', value: 5 },
//     { label: 'PST', value: 7 },
//     { label: 'GST & PST', value: 12 },
// ];

// // TEMPORARY
// export const PURCHASERS = [
//     { id: 1, name: 'Huss Solutions' },
//     { id: 1, name: 'Gavilon' },
//     { id: 1, name: 'JGL' },

// ];
// export const COMMODITIES = [
//     'Barley',
//     'Wheat',
//     'DDG',
//     'Canola Meal',
//     'Corn',
// ];