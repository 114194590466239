import { Form, Tabs, Typography } from "antd";
import React, { useState } from "react";
import UserFormDetails from "./UserFormDetails";
import UserFormPermissions from "./UserFormPermissions";
import { ADMIN, USER } from "./types";

const layout = {
    labelCol: { span: 30 },
    wrapperCol: { span: 30 },
};
const UserForm = ({ user, form, submitForm, props, isAdmin = false, isCreate = false }: any) => {

    const { canCreateContracts, setCanCreateContracts, canAccessContracts, setCanAccessContracts, canEditContracts, setCanEditContracts, canAccessContractsHistory, setCanAccessContractsHistory, canAccessReportsAndInvoicing, setCanAccessReportsAndInvoicing, canAccessUsers, setCanAccessUsers, canAccessCompanyInfo, setCanAccessCompanyInfo, canAccessCompanyPreferences, setCanAccessCompanyPreferences } = props;
    const [activeTab, setActiveTab] = useState('User Details');

    // SET PERMISSION DEFAULTS IF EMPLOYEE TYPE CHANGES ON CREATING A USER
    // ADMIN CAN MAKE ADDITIONAL SELECTIONS AND MODIFICATIONS AS REQUIRED.
    const handleEmployeeTypeChange = (value: any) => {
        switch (value) {
            case ADMIN:
                setCanAccessContracts(true);
                setCanCreateContracts(true);
                setCanEditContracts(true);
                setCanAccessContractsHistory(true);
                setCanAccessReportsAndInvoicing(true);
                setCanAccessUsers(false);
                setCanAccessCompanyInfo(false);
                setCanAccessCompanyPreferences(false);
                break;
            case USER:
                setCanAccessContracts(true);
                setCanCreateContracts(false);
                setCanEditContracts(false);
                setCanAccessContractsHistory(false);
                setCanAccessReportsAndInvoicing(false);
                setCanAccessUsers(false);
                setCanAccessCompanyInfo(false);
                setCanAccessCompanyPreferences(false);
                break;
            default:
                setCanAccessContracts(false);
                setCanCreateContracts(false);
                setCanEditContracts(false);
                setCanAccessContractsHistory(false);
                setCanAccessReportsAndInvoicing(false);
                setCanAccessUsers(false);
                setCanAccessCompanyInfo(false);
                setCanAccessCompanyPreferences(false);
                break;
        }
    };

    return (
        <div className="user-form">
            <Form
                form={form}
                labelCol={layout.labelCol}
                wrapperCol={layout.wrapperCol}
                layout="vertical"
                name="user_form"
                id="user_form"
                style={{ padding: '1rem' }}
                initialValues={{
                    remember: true,
                    'can_create_contracts': user?.can_create_contracts,
                    'can_access_contracts': user?.can_access_contracts,
                    'can_edit_contracts': user?.can_edit_contracts,
                    'can_access_contractshistory': user?.can_access_contractshistory,
                    'can_access_reports_andinvoicing': user?.can_access_reports_andinvoicing,
                    'can_access_users': user?.can_access_users,
                    'can_access_company_info': user?.can_access_company_info,
                    'can_access_company_preferences': user?.can_access_company_preferences
                }}
                onFinish={submitForm}
            >

                <Tabs style={{ height: '100%' }} onChange={setActiveTab} type='card' activeKey={activeTab} >
                    <Tabs.TabPane style={{ height: '100%' }} tab='User Details' key='User Details'>
                        <Typography.Title>User Details</Typography.Title>
                        <UserFormDetails isCreate={isCreate} isAdmin={isAdmin} handleEmployeeTypeChange={handleEmployeeTypeChange} />
                    </Tabs.TabPane>
                    {(user?.can_access_users || isAdmin) &&
                        < Tabs.TabPane style={{ height: '100%' }} tab='User Permissions' key='User Permissions'>
                            <Typography.Title>User Permissions</Typography.Title>
                            <UserFormPermissions
                                canCreateContracts={canCreateContracts}
                                setCanCreateContracts={setCanCreateContracts}
                                canAccessContracts={canAccessContracts}
                                setCanAccessContracts={setCanAccessContracts}
                                canEditContracts={canEditContracts}
                                setCanEditContracts={setCanEditContracts}
                                canAccessContractsHistory={canAccessContractsHistory}
                                setCanAccessContractsHistory={setCanAccessContractsHistory}
                                canAccessReportsAndInvoicing={canAccessReportsAndInvoicing}
                                setCanAccessReportsAndInvoicing={setCanAccessReportsAndInvoicing}
                                canAccessUsers={canAccessUsers}
                                setCanAccessUsers={setCanAccessUsers}
                                canAccessCompanyInfo={canAccessCompanyInfo}
                                setCanAccessCompanyInfo={setCanAccessCompanyInfo}
                                canAccessCompanyPreferences={canAccessCompanyPreferences}
                                setCanAccessCompanyPreferences={setCanAccessCompanyPreferences}
                            />
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Form>
        </div >
    );
};

export default UserForm;