/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, Input, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import { Company } from "routes/userManagement/types";


const SearchAndFilterContracts = ({ onSearch, commodities, companies, resetTable, setResetTable }: any): JSX.Element => {
    const [form] = Form.useForm();

    const [searchText, setSearchText] = useState("");
    const [searchCommodities, setSearchCommodities] = useState("");
    const [searchPartners, setSearchPartners] = useState("");

    useEffect(() => {
        onSearch(searchText, searchCommodities, searchPartners);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, searchCommodities, searchPartners]);

    const ResetTable = () => {
        setResetTable(true);
        // CLEAR DROP DOWNS AND SEARCH BAR
        form.setFieldsValue({
            'search_text': '',
            'search_commodities': '',
            'search_partners': ''
        });
        setSearchText('');
        setSearchCommodities('');
        setSearchPartners('');
    };
    return (
        <Form
            form={form}
        >
            <Row justify="center" style={{ paddingTop: '1rem' }}>
                <Col style={{ paddingLeft: '1rem' }}>
                    <FormItem name="search_text">
                        <Input.Search placeholder="Search Contracts..." onSearch={(value) => { setSearchText(value); }} />
                    </FormItem>
                </Col>
                <Col style={{ paddingLeft: '1rem' }}>
                    {
                        Object.keys(commodities).length > 0 &&
                        <FormItem name="search_commodities">
                            <Select placeholder="Commodities Search" onChange={(value) => { setSearchCommodities(value); }}>
                                <Select.Option key="" value="">
                                    All Commodities
                                </Select.Option>
                                {Object.values(commodities as any[]).map((commodity) => (
                                    <Select.Option key={commodity} value={commodity}>
                                        {commodity}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    }
                </Col>
                <Col style={{ paddingLeft: '1rem' }}>
                    {
                        Object.keys(companies).length > 0 &&
                        <FormItem name="search_partners">
                            <Select placeholder="Partners Search" onChange={(value) => { setSearchPartners(value); }}>
                                <Select.Option key="" value="">
                                    All Partners
                                </Select.Option>
                                {Object.values(companies as Company[]).map((company: Company) => (
                                    <Select.Option key={company.name} value={company.name}>
                                        {company.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    }
                </Col>
                <Col style={{ paddingLeft: '1rem' }}>
                    <Select defaultValue="CAD">
                        <Select.Option value="CAD">CAD</Select.Option>
                        <Select.Option disabled value="USD">USD</Select.Option>
                        <Select.Option disabled value="EUR">EUR</Select.Option>
                        <Select.Option disabled value="GBP">GBP</Select.Option>
                    </Select>
                </Col>
                <Col style={{ paddingLeft: '1rem' }}>
                    <Select defaultValue="Metric Tonne">
                        <Select.Option disabled value="US Ton">US Ton</Select.Option>
                        <Select.Option value="Metric Tonne">Metric Tonne</Select.Option>
                    </Select>
                </Col>
                <Col style={{ paddingLeft: '1rem' }}>
                    <Button type="primary" onClick={ResetTable}>Reset Table</Button>
                </Col>
            </Row >
        </Form>
    );
};
export default SearchAndFilterContracts;