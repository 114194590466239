import MainLayout from 'components/layouts/MainLayout';
import React, { useEffect } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { Spin } from 'antd';

import ContractsApiProvider from 'routes/contracts/ContractDataProvider';
import LoadsApiProvider from 'routes/loads/LoadsDataProvider';
import { useAuth } from './authProvider';

interface Props {
    exact: boolean | undefined
    path: string | undefined
    component: React.ComponentType
}

export const LoadingContentIndicator = () => (
    <div className="loading-content center-flex">
        <Spin />
    </div>
);

export default function ProtectedRoute(props: Props) {
    const auth = useAuth();
    const { push } = useHistory();
    useEffect(() => {
        if (!auth.user && !auth.isFetchingUser) {
            auth.getUser({ onFail: () => push('/login') });
        }
        // eslint-disable-next-line
    }, []);

    const { exact, path, component: Component } = props;
    return (
        <ContractsApiProvider>
            <LoadsApiProvider>
                <Route exact={exact} path={path} render={() => (
                    <MainLayout>
                        {!auth.user ? <LoadingContentIndicator /> : <Component />}
                    </MainLayout>
                )} />
            </LoadsApiProvider>
        </ContractsApiProvider>
    );
}
