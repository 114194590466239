import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import FeedbackModal from "./FeedbackModal";

const SubmitFeedbackLink = (props: any) => {
    const { isSiderCollapsed } = props;
    const [linkText, setLinkText] = useState('Submit Feedback');
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

    useEffect(() => {
        if (isSiderCollapsed) {
            setLinkText('Feedback');
        } else {
            setLinkText('Submit Feedback');
        }
    }, [isSiderCollapsed]);
    return (
        <div className="feedback">
            <Button type="link" onClick={() => { setIsFeedbackModalVisible(true); }}>{linkText}</Button>
            <FeedbackModal isFeedbackModalVisible={isFeedbackModalVisible} setIsFeedbackModalVisible={setIsFeedbackModalVisible} />
        </div>
    );
};

export default SubmitFeedbackLink;