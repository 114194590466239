/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import { useAuth } from "components/auth/authProvider";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useContractsApi } from "routes/contracts/ContractDataProvider";
import { useLoadsApi } from "./LoadsDataProvider";


const layout = {
    labelCol: { span: 30 },
    wrapperCol: { span: 30 },
};


const LoadForm = (props: any) => {
    const { load, setEditMode, isSeller, pricePerTonne, contractId, status } = props;
    const { setEditLoad, updateLoad } = useLoadsApi();
    const { setRequestRefetch } = useContractsApi();
    const [disabled, setDisabled] = useState(false);
    const auth = useAuth();
    const [form] = Form.useForm();

    useEffect(() => {
        const values = form.getFieldsValue();
        Object.assign(values, load);
        values.dispatch_date = load.dispatch_date ? moment(load.dispatch_date) : null;
        values.arrival_date = load.arrival_date ? moment(load.arrival_date) : null;
        if (load.id === 0) {
            values.id = null;
        }
        form.setFieldsValue(values);
    }, [load, form]);

    useEffect(() => {
        if (status !== 'ACTIVE') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [status]);

    const submitForm = useCallback(() => {
        const values = form.getFieldsValue();
        const datas = {
            ...values,
            dispatch_date: values.dispatch_date ? values.dispatch_date.format("YYYY-MM-DD") : null,
            arrival_date: values.arrival_date ? values.arrival_date.format("YYYY-MM-DD") : null,
            id: values.id ?? 0,
            uuid: auth.user.company_id,
            contract: +contractId
        };
        updateLoad(datas, load.id, () => setRequestRefetch(true));
        setEditLoad();
        setEditMode(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const UpdateDollarValue = (quantity: any) => {
        if (quantity) {
            form.setFields([{
                name: "dollar_value",
                value: (quantity * pricePerTonne)
            }]);
        }
    };

    const colProps = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };
    return (
        <div>
            <Form
                form={form}
                labelCol={layout.labelCol}
                wrapperCol={layout.wrapperCol}
                layout='vertical'
                name="create_load_form"
                id="create_load_form"
                style={{ padding: '1rem' }}
                initialValues={{ remember: true }}
                onFinish={submitForm}
            >
                <Row>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>

                        <Form.Item
                            label={<div className="input-label">Load #</div>}
                            name="id"
                        >
                            <Input placeholder="(Auto Populates)" disabled style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        <Form.Item
                            label={<div className="input-label">Dispatch Date</div>}
                            name="dispatch_date"
                        >
                            <DatePicker style={{ width: "100%" }} disabled={!isSeller || disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        <Form.Item
                            label={<div className="input-label">Carrier</div>}
                            name="carrier"
                        >
                            <Input placeholder="Carrier" style={{ width: "100%" }} disabled={!isSeller || disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        {/* </Row>
                <Row> */}
                        <Form.Item
                            label={<div className="input-label">Arrival Date</div>}
                            name="arrival_date"
                        >
                            <DatePicker style={{ width: "100%" }} disabled={isSeller || disabled} />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        <Form.Item
                            label={<div className="input-label">Scale Ticket Number</div>}
                            name="scale_ticket_number"
                        >
                            <Input placeholder="Scale Ticket Number" style={{ width: "100%" }} disabled={isSeller || disabled} />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        {/* <Form.Item
                        label={<div className="input-label">Delivered Weight</div>}
                        name="delivered_weight"
                    >
                        <InputNumber placeholder="Delivered Weight" min={0} style={{ width: "100%" }} disabled={isSeller} />
                    </Form.Item> */}
                        {/* </Row>
                <Row> */}
                        <Form.Item
                            label={<div className="input-label">Quantity Received</div>}
                            name="quantity_received"
                        >
                            <InputNumber placeholder="Quantity Received" min={0} style={{ width: "100%" }} disabled={isSeller || disabled} onChange={UpdateDollarValue} addonAfter='t' />
                        </Form.Item>
                    </Col>
                    <Col {...colProps} style={{ padding: '0.25rem' }}>
                        <Form.Item
                            label={<div className="input-label">Dollar Value</div>}
                            name="dollar_value"
                        >
                            <InputNumber placeholder="Dollar Value" min={0} precision={2} style={{ width: "100%" }} disabled />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
            <Row>
                <Button
                    form="create_load_form"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                >
                    Save Load
                </Button>
            </Row>
        </div>
    );
};

export default LoadForm;