import { Company } from "routes/userManagement/types";

export function getStatusText(status: string): string {
    switch (status) {
        case 'SIGNATURE_REQUIRED':
            return 'Signature Required';
        case 'SIGNATURE_PENDING':
            return 'Pending Signature';
        case 'SELLER_REQUESTED_COMPLETION':
        case 'PURCHASER_REQUESTED_COMPLETION':
            return 'Completion Requested';
        case 'SELLER_REQUESTED_CANCELLATION':
        case 'PURCHASER_REQUESTED_CANCELLATION':
            return 'Cancellation Requested';
        case 'SELLER_REQUESTED_EDIT':
            return 'Edit Requested';
        case 'ACTIVE':
            return 'Active';
        default:
            break;
    }
    return '';
}

export function metricToUSTon(quantity: number) {
    return quantity * 1.10231;
}
export function USToMetricTonne(quantity: number) {
    return quantity * 0.907185;
}

export function cleanData(data: any) {
    const cleanedData = data;
    if (data.id === 0) {
        delete cleanedData.id;
    }
    delete cleanedData.created_at;
    return cleanedData;
}

export function isString(x: any) {
    return Object.prototype.toString.call(x) === "[object String]" || typeof x === 'string';
}

export function isObject(x: any) {
    return x !== null && typeof x === 'object';
}

function stringOrEmpty(value: any) {
    if (value && isString(value) && value !== 'null') {
        return value;
    }
    return '';
}
export function valueOrNotSpecified(data: any) {
    const value = stringOrEmpty(String(data));
    return !value || value.trim() === '' ? 'Not Specified' : value;
}
export function formatCompanyAddress(company: Company) {
    if (company) {
        const address = `${stringOrEmpty(company.address_1)} ${stringOrEmpty(company.address_2)} ${stringOrEmpty(company.city)} ${stringOrEmpty(company.postal_code)} ${stringOrEmpty(company.province)}`;
        return valueOrNotSpecified(address);
    }
    return null;
}

export function getTimezones() {

    return [
        { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
        { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
        { name: "(GMT -10:00) Hawaii", value: "-10:00" },
        { name: "(GMT -9:30) Taiohae", value: "-09:50" },
        { name: "(GMT -9:00) Alaska", value: "-09:00" },
        { name: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
        { name: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00", selected: true },
        { name: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06:00" },
        { name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05:00" },
        { name: "(GMT -4:30) Caracas", value: "-04:50" },
        { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00" },
        { name: "(GMT -3:30) Newfoundland", value: "-03:50" },
        { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
        { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
        { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
        { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00" },
        { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
        { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
        { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00" },
        { name: "(GMT +3:30) Tehran", value: "+03:50" },
        { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
        { name: "(GMT +4:30) Kabul", value: "+04:50" },
        { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00" },
        { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
        { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
        { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
        { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
        { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
        { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00" },
        { name: "(GMT +8:45) Eucla", value: "+08:75" },
        { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
        { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
        { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00" },
        { name: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
        { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00" },
        { name: "(GMT +11:30) Norfolk Island", value: "+11:50" },
        { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00" },
        { name: "(GMT +12:45) Chatham Islands", value: "+12:75" },
        { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
        { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" }
    ];
}

export default 2;