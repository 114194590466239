import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Row, } from "antd";
import { useContractsApi } from "./ContractDataProvider";

const ContractActionSection = (props: any) => {
    const { status, isSeller, contractId, editRequestId, ReviewAndSignAction } = props;
    const [message, setMessage] = useState('');
    const [description, setDescription] = useState('');
    const [actions, setActions] = useState<JSX.Element | JSX.Element[]>(<></>);

    const { cancelContract, completeContract, deleteEditRequest } = useContractsApi();
    const { push } = useHistory();

    const GetUndoButton = (undoFunction: () => void) => (
        <Row style={{ float: 'right', marginTop: '2rem' }}>
            <Button ghost type="primary" onClick={undoFunction}>
                Undo
            </Button>
        </Row>
    );

    const GetAcceptDeclineButtons = (acceptFunction: () => void, declineFunction: () => void) => (
        <Row style={{ float: 'right', marginTop: '2rem' }}>
            <Button danger type="ghost" onClick={declineFunction}>
                Decline
            </Button>
            <Button type="primary" onClick={acceptFunction}>
                Accept
            </Button>
        </Row>
    );

    const GetReviewAndSignDeclineButtons = (declineFunction: () => void) => (
        <Row style={{ float: 'right', marginTop: '2rem' }}>
            <Button style={{ marginRight: '1rem' }} danger type="ghost" onClick={declineFunction}>
                Decline
            </Button>
            <Button type="primary" onClick={ReviewAndSignAction}>
                Review & Sign
            </Button>
        </Row>
    );

    useEffect(() => {
        switch (status) {
            case 'SELLER_REQUESTED_COMPLETION':
                if (isSeller) {
                    setMessage('Completion Request Pending');
                    setDescription('You have submitted a completion request to the purchaser. If you wish to undo this cancellation request please click the "Undo" button below.');
                    setActions(GetUndoButton(() => completeContract(contractId, isSeller, true, () => { push('/contracts'); })));
                } else {
                    setMessage('Completion Requested');
                    setDescription('The seller has requested completion of this contract. To agree and mark this contract as complete please click the "Accept" button below. If you do not want to mark this contract as complete please click the "Reject" button below.');
                    setActions(GetAcceptDeclineButtons(() => completeContract(contractId, !isSeller, false, () => { push('/contracts'); }), () => completeContract(contractId, !isSeller, true, () => { push('/contracts'); })));
                }
                break;
            case 'PURCHASER_REQUESTED_COMPLETION':
                if (!isSeller) {
                    setMessage('Completion Request Pending');
                    setDescription('You have submitted a completion request to the seller. If you wish to undo this cancellation request please click the "Undo" button below.');
                    setActions(GetUndoButton(() => completeContract(contractId, isSeller, true, () => { push('/contracts'); })));
                } else {
                    setMessage('Completion Requested');
                    setDescription('The purchaser has requested completion of this contract. To agree and mark this contract as complete please click the "Accept" button below. If you do not want to mark this contract as complete please click the "Reject" button below.');
                    setActions(GetAcceptDeclineButtons(() => completeContract(contractId, !isSeller, false, () => { push('/contracts'); }), () => completeContract(contractId, !isSeller, true, () => { push('/contracts'); })));
                }
                break;
            case 'PURCHASER_REQUESTED_CANCELLATION':
                if (!isSeller) {
                    setMessage('Cancellation Request Pending');
                    setDescription('You have submitted a cancellation request to the seller. If you wish to undo this cancellation request please click the "Undo" button below.');
                    setActions(GetUndoButton(() => cancelContract(contractId, isSeller, true, () => { push('/contracts'); })));
                } else {
                    setMessage('Cancellation Requested');
                    setDescription('The purchaser has requested a cancellation of this contract. To agree and terminate this contract please click the "Accept" button below. If you do not want to cancel please click the "Reject" button below.');
                    setActions(GetAcceptDeclineButtons(() => cancelContract(contractId, !isSeller, false, () => { push('/contracts'); }), () => cancelContract(contractId, !isSeller, true, () => { push('/contracts'); })));
                }
                break;
            case 'SELLER_REQUESTED_CANCELLATION':
                if (isSeller) {
                    setMessage('Cancellation Request Pending');
                    setDescription('You have submitted a cancellation request to the purchaser. If you wish to undo this cancellation request please click the "Undo" button below.');
                    setActions(GetUndoButton(() => cancelContract(contractId, isSeller, true, () => { push('/contracts'); })));
                } else {
                    setMessage('Cancellation Requested');
                    setDescription('The seller has requested a cancellation of this contract. To agree and terminate this contract please click the "Accept" button below. If you do not want to cancel please click the "Reject" button below.');
                    setActions(GetAcceptDeclineButtons(() => cancelContract(contractId, !isSeller, false, () => { push('/contracts'); }), () => cancelContract(contractId, !isSeller, true, () => { push('/contracts'); })));
                }
                break;
            case 'SELLER_REQUESTED_EDIT':
                if (isSeller) {
                    setMessage('Edit Request Pending');
                    setDescription('You have submitted an edit request to the purchaser. If you wish to undo this edit request please click the "Undo" button below.');
                    setActions(GetUndoButton(() => deleteEditRequest(contractId, editRequestId, () => { push('/contracts'); })));
                } else {
                    setMessage('Edit Requested');
                    setDescription('A request to edit the contract was sent to you by the seller. Please use the sign tab to review the changes. If you agree to the changes please sign. If you do not wish to accept the changes please click the "Decline" button below.');
                    setActions(GetReviewAndSignDeclineButtons(() => deleteEditRequest(contractId, editRequestId, () => { push('/contracts'); })));
                }
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isSeller, editRequestId]);

    return (
        <div key='contract_action'>
            {
                message !== '' ? [<Alert
                    message={message}
                    description={[description, actions]}
                    type="warning"
                    style={{ margin: '2rem' }}
                />] : []
            }
        </div>
    );
};

export default ContractActionSection;
