/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row } from "antd";
import { useAuth } from "components/auth/authProvider";
import React, { useEffect } from "react";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import CompanyCard from "./company/CompanyCard";
import CompanyModal from "./company/CompanyModal";
import UserCard from "./user/UserCard";
import UserModal from "./user/UserModal";
import UsersCard from "./users/UsersCard";
import UsersModal from "./users/UsersModal";

const UserManagement = () => {
    const { path } = useRouteMatch();
    const { user, updateUser,
        company, getCompany,
        updateCompany,
        getUsers, users, isFetchingUsers,
        createUser, isCreatingUser, isUpdatingUser } = useAuth();

    const { push } = useHistory();

    useEffect(() => {
        if (user && user.can_access_users && users.length === 0 && !isFetchingUsers) {
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, users, isFetchingUsers]);

    useEffect(() => {
        if (!company) {
            getCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    const userClick = () => {
        push('/profile/user');
    };
    const companyClick = () => {
        push('/profile/company');
    };

    const Actions = () => {
        const { action, subAction, id }: { action: string, subAction: string, id: string } = useParams();
        if (isCreatingUser || isUpdatingUser) { return null; }
        switch (action) {
            case 'user':
                return (<UserModal user={user} push={push} updateUser={updateUser} />);
            case 'company':
                return (<CompanyModal company={company} push={push} updateCompany={updateCompany} />);
            case 'users':
                if (subAction && id) {
                    const userToUpdate = users.find(x => x.id === +id);
                    return (<UsersModal user={userToUpdate} push={push} updateUser={updateUser} adminUser={user} />);
                }
                return (<UsersModal user={{}} push={push} createUser={createUser} adminUser={user} />);
            default:
                return <></>;
        }
    };

    const colProps = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
    const usersColProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
    return (
        <div className='user-management-content'>
            <Row gutter={16}>
                <Col {...colProps} style={{ padding: '1rem' }}>
                    <UserCard onClick={userClick} />
                </Col>
                <Col {...colProps} style={{ padding: '1rem' }}>
                    <CompanyCard onClick={companyClick} />
                </Col>
                <Col {...usersColProps} style={{ padding: '1rem' }}>
                    <UsersCard users={users} user={user} />
                </Col>
            </Row>

            <Route path={`${path}/:action`} exact component={Actions} />
            <Route path={`${path}/:action/:subAction`} exact component={Actions} />
            <Route path={`${path}/:action/:subAction/:id`} exact component={Actions} />
        </div >

    );
};
export default UserManagement;
