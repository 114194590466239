/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Card, Col, Row, Space, Spin, Typography } from "antd";
import { useAuth } from "components/auth/authProvider";
import { User } from "components/auth/types";
import React from "react";
import { valueOrNotSpecified } from "utils/dataUtils";

const styles = {
    loadingSpinner: {
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        alignItems: 'center'
    },
};
const UserCard = ({ onClick }: any) => {
    const { user } = useAuth();
    return (
        <div className="user-card">
            {!user ? <Spin style={styles.loadingSpinner} /> :
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                    <Card title="Profile" hoverable size="small" onClick={onClick}>
                        <Row>
                            <Col style={{ paddingLeft: '2rem' }}>
                                <Row>
                                    <Typography.Title level={5}> <b>Name: </b>{(user as User)?.first_name} {(user as User)?.last_name}</Typography.Title>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}> <MailOutlined /> <b>Email: </b>{(user as User)?.email}</Typography.Title>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}> <PhoneOutlined /> <b>Phone: </b>{valueOrNotSpecified((user as User)?.user_phone)}</Typography.Title>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}> <b>Job Title: </b>{valueOrNotSpecified((user as User)?.job_title)}</Typography.Title>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}> <b>Employee Type: </b>{valueOrNotSpecified((user as User)?.employee_type)}</Typography.Title>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Space>
            }
        </div>
    );
};
export default UserCard;
