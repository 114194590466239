/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, Form, Input, Modal, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import { useAuth } from "./authProvider";

const colProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
const nameColProps = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
const FeedbackModal = (props: any) => {
    const { isFeedbackModalVisible, setIsFeedbackModalVisible } = props;

    const { sendFeedback, user } = useAuth();

    const [form] = Form.useForm();

    const handleCancel = () => {
        form.resetFields();
        setIsFeedbackModalVisible(false);
    };

    const handleSubmitSuccess = () => {
        handleCancel();
        Modal.success({
            content: 'Your feedback has been received by our team. Thank you!',
        });
    };

    const submitForm = useCallback(() => {
        const values = form.getFieldsValue();
        const datas = {
            ...values,
            company_id: user.company_id
        };

        sendFeedback(datas, handleSubmitSuccess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, user]);

    return <Modal
        title="Feedback"
        maskClosable={false}
        visible={isFeedbackModalVisible}
        onCancel={handleCancel}
        footer={[
            <Button key="back" onClick={handleCancel} style={{ float: 'left' }}>
                Cancel
            </Button>,
            <Button
                form="feedback_form"
                key="submit"
                htmlType="submit"
                type="primary"
                style={{ marginLeft: 'auto' }}
            >
                Submit
            </Button>
        ]}
    >
        <Form
            form={form}
            layout="vertical"
            name="feedback_form"
            id="feedback_form"
            style={{ padding: '1rem' }}
            onFinish={submitForm}
            initialValues={{ remember: true, }}
        >
            <Row>
                <Col {...nameColProps} style={{ padding: "0.25rem" }}>
                    <FormItem
                        rules={[{ required: true, message: "First Name is required." }]}
                        label={<div className="input-label">First Name</div>} name="first_name"
                    >
                        <Input name="first_name" placeholder="First Name" />
                    </FormItem>
                </Col>
                <Col {...nameColProps} style={{ padding: "0.25rem" }}>
                    <FormItem
                        rules={[{ required: true, message: "Last Name is required." }]}
                        label={<div className="input-label">Last Name</div>} name="last_name"
                    >
                        <Input name="last_name" placeholder="Last Name" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col {...colProps} style={{ padding: "0.25rem" }}>
                    <FormItem
                        rules={[{ required: true, message: "Email Address is required." }]}
                        label={<div className="input-label">Email Address</div>} name="email"
                    >
                        <Input name="email" placeholder="Email Address" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col {...colProps} style={{ padding: "0.25rem" }}>
                    <FormItem
                        rules={[
                            { required: true, message: "Feedback is required." },
                            { max: 2000, message: "Feedback message cannot exceed 2000 characters." }
                        ]}
                        label={<div className="input-label">Feedback</div>} name="message"
                    >
                        <TextArea name="message" placeholder="Feedback..." rows={5} maxLength={2000} />
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </Modal >;
};

export default FeedbackModal;