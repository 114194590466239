/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Col, Input, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { getTimezones } from "utils/dataUtils";

const CompanyFormPreferences = () => {
    const colProps = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
    return (
        <Row>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">GST Number</div>} name="gstNumber"
                >
                    <Input name="gst_number" placeholder="GST Number" />
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Currency Symbol</div>} name="currencySymbol"
                >
                    <Select placeholder="Currency Symbol" style={{ width: '100%' }} disabled>
                        <Select.Option value="$">$</Select.Option>
                    </Select>
                </FormItem>
            </Col>

            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem
                    label={<div className="input-label">Timezone</div>} name="timezone"
                >
                    <Select placeholder="Timezone" style={{ width: '100%' }}>
                        {Object.values(getTimezones()).map((tz) => (
                            <Select.Option key={tz.name} value={tz.value}>
                                {tz.name}
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Col>
        </Row>
    );
};

export default CompanyFormPreferences;