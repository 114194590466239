// Libs
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Card, Form, Image, Typography } from 'antd';
import { postData } from 'utils/network';

// Components
import { useAuth } from 'components/auth/authProvider';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const styles = {
  layout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  mainCard: { maxWidth: '600px', width: '80vw', margin: '10rem auto auto', boxShadow: '0px 0px 5px 1px #4daa48' },
  input: {
    maxWidth: '200px', width: '50vw'
  },
  errorAlert: {
    width: 'max-content',
    maxWidth: '50vw',
    margin: 'auto'
  },
  inputLabel: { fontWeight: 600 },
  title: { paddingTop: '20vh' }
} as { [key: string]: React.CSSProperties };

const createUserApi = '/api/accounts/register-new-company/';

function CreateAdminUserAndCompany() {
  const history = useHistory();
  const auth = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [networkErrors, setNetworkErrors] = useState("");

  const submitForm = useCallback(({ password = '', email = '', companyName, firstName, lastName }) => {
    setIsSubmitting(true);
    postData(createUserApi, {
      password,
      email,
      username: email,
      companyName,
      firstName,
      lastName
    }).then(response => {
      if (response.status === 201) {
        auth.login(email, password, false, () => history.push('/'));
      } else {
        setIsSubmitting(false);
        response.json().then(errorMessage => {
          if (errorMessage?.detail) {
            setNetworkErrors(errorMessage.detail);
          } else {
            setNetworkErrors("error submitting new user and company");
          }

        });
      }
    });

    // eslint-disable-next-line 
  }, [history]);

  return (
    <div style={styles.layout}>
      <Card style={styles.mainCard} title={<div>Create New User and Company</div>}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to='/login'>
            <Image src="/static/cc_logo_fbgreen_512.png" alt='Contract Connection Logo' preview={false} style={{ paddingBottom: '1rem' }} />
          </Link>
        </div>
        <Form
          labelCol={layout.labelCol}
          wrapperCol={layout.wrapperCol}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={submitForm}

        >
          <Form.Item
            label={<div style={styles.inputLabel}>First Name</div>}
            name='firstName'
            rules={[{ required: true, message: 'Please input your First Name!' }]}
            className="login-page-input"
          >
            <Input style={styles.input} />
          </Form.Item>
          <Form.Item
            label={<div style={styles.inputLabel}>Last Name</div>}
            name='lastName'
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
            className="login-page-input"
          >
            <Input style={styles.input} />
          </Form.Item>
          <Form.Item
            label={<div style={styles.inputLabel}>Company Name</div>}
            name='companyName'
            rules={[{ required: true, message: 'Please input your Company Name' }]}
            className="login-page-input"
          >
            <Input style={styles.input} />
          </Form.Item>
          <Form.Item
            label={<div style={styles.inputLabel}>Email</div>}
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
            className="login-page-input"

          >
            <Input style={styles.input} />
          </Form.Item>
          <Form.Item
            label={<div style={styles.inputLabel}>Password</div>}
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 6, message: 'Password must be minimum 6 characters.' },]}
            className="login-page-input"
          >
            <Input.Password style={styles.input} />
          </Form.Item>
          <Form.Item
            label={<div style={styles.inputLabel}>Confirm Password</div>}
            name="confirm-password"
            rules={[{ required: true, message: 'Please confirm your password!' },
            { min: 6, message: 'Password must be minimum 6 characters.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
            ]}
            className="login-page-input"
          >
            <Input.Password style={styles.input} />
          </Form.Item>
          {networkErrors ? <Typography.Text className='ant-form-item-explain-error center-flex'>{networkErrors}</Typography.Text> : null}
          <Form.Item wrapperCol={tailLayout.wrapperCol} >
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default React.memo(CreateAdminUserAndCompany);
