/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */

import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import { useLoadsApi } from "./LoadsDataProvider";
import { Attachment } from './types';

const UploadAttachment = (props: any) => {
    const { loadId, attachments } = props;
    const { postLoadAttachment, deleteLoadAttachment, downloadAttachment } = useLoadsApi();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        if (fileList.length === 0 && attachments.length > 0) {
            const newFileList: UploadFile[] = [];
            (attachments as Attachment[]).forEach(attachment => {
                const newFile = { uid: attachment.id.toString(), name: attachment.name, status: 'done', url: attachment.url };
                newFileList.push(newFile as UploadFile);
            });
            setFileList(newFileList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachments]);

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const uploadList = { showPreviewIcon: false, showDownloadIcon: true, showRemoveIcon: true };
    const uploadProps = {
        onRemove: (file: UploadFile) => {
            deleteLoadAttachment(loadId, +file.uid, () => {
                const newFileList = fileList.filter(x => x.uid !== file.uid);
                setFileList(newFileList);
            });
        },
        beforeUpload: (file: RcFile) => {
            const data = new FormData();
            data.append('load', loadId);
            data.append('file', file);
            postLoadAttachment(loadId, data, (attachment: Attachment) => {
                const newFile = { uid: attachment.id.toString(), name: attachment.name, status: 'done', url: attachment.url };
                setFileList([newFile as UploadFile, ...fileList]);
            });
            return false;
        },
        onPreview: (file: UploadFile) => {
            if (file.url) {
                downloadAttachment(file.url);
            }
        },
        onDownload: (file: UploadFile) => {
            if (file.url) {
                downloadAttachment(file.url);
            }
        },
        onChange,
        fileList,
    };

    return (
        <div className="upload-attachments">
            <Upload  {...uploadProps} showUploadList={uploadList}>
                <Button>
                    <UploadOutlined /> Select File
                </Button>
            </Upload>
        </div>
    );
};

export default UploadAttachment;