export interface Company {
    id: number;
    name: string;
    website: string;
    address_1: string;
    address_2: string;
    city: string;
    province: string;
    postal_code: string;
    email: string;
    phone: string;
    fax: string;
    gstNumber: string;
    currencySymbol: string;
    timeZone: string;
    dateFormat: string;
    distanceUnit: string;
}

export enum Provinces {
    ALBERTA = 'Alberta',
    BRITISH_COLUMBIA = 'British Columbia',
    MANITOBA = 'Manitoba',
    NEW_BRUNSWICK = 'New Brunswick',
    NEWFOUNDLAND_AND_LABRADOR = 'Newfoundland and Labrador',
    NORTHWEST_TERRITORIES = 'Northwest Territories',
    NOVA_SCOTIA = 'Nova Scotia',
    NUNAVUT = 'Nunavut',
    ONTARIO = 'Ontario',
    PRINCE_EDWARD_ISLAND = 'Prince Edward Island',
    QUEBEC = 'Quebec',
    SASKATCHEWAN = 'Saskatchewan',
    YUKON = 'Yukon'
}