// export interface Load {

// }

export default class Load implements Load {
  id!: number;

  contract!: number;

  uuid!: number;

  carrier!: string;

  scale_ticket_number!: string;

  delivered_weight!: number;

  dollar_value!: number;

  quantity_received!: number;

  arrival_date!: string;

  dispatch_date!: string;

  constructor({ ...props }) {
    Object.assign(this, props);
  }
}

export interface Attachment {
  id: number,
  load: number,
  size: number,
  url: string,
  name: string
}