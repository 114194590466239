/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { ColumnsType } from "antd/lib/table";
import { useAuth } from "components/auth/authProvider";
import { valueOrNotSpecified } from "utils/dataUtils";
import { useHistory } from "react-router-dom";
import { User } from "components/auth/types";
import { Button, Modal, Row } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import * as user from '../../../components/auth/model';

const Columns = () => {
    const { confirm } = Modal;
    const { push } = useHistory();
    const { deleteUser } = useAuth();

    const GetEditButton = (record: User): JSX.Element => {
        /* eslint-disable react/destructuring-assignment */
        const button = <Button onClick={() => { push(`/profile/users/edit/${record.id}`); }}>
            <EditOutlined />
        </Button>;
        return button;
    };

    const showDeleteConfirm = (record: User) => {
        confirm({
            title: 'Are you sure you would like to delete this user?',
            content: 'Deleting a user is permanent. Please make sure you want to delete.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteUser(record.id);
            }
        });
    };
    const columns: ColumnsType<User> = [
        {
            title: 'Id',
            dataIndex: user.PROP_ID,
            defaultSortOrder: 'descend',
            sorter: (a: User, b: User) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: user.PROP_FIRST_NAME,
            sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
            render: (text, record) => `${record.first_name} ${record.last_name}`,
        },

        {
            title: 'Email',
            dataIndex: user.PROP_EMAIL,
            sorter: (a: User, b: User) => a.email.localeCompare(b.email),
        },

        {
            title: 'Phone #',
            dataIndex: user.PROP_USER_PHONE,
            sorter: (a: User, b: User) => a.user_phone.localeCompare(b.user_phone),
        },
        {
            title: 'Job Title',
            dataIndex: user.PROP_JOB_TITLE,
            sorter: (a: User, b: User) => a.job_title.localeCompare(b.job_title),
            render: (text, record) => `${valueOrNotSpecified(record.job_title)}`
        },
        {
            title: 'Actions',
            dataIndex: '',
            render: (text, record) =>
                <Row>
                    {GetEditButton(record)}
                    <Button onClick={() => showDeleteConfirm(record)}>
                        <DeleteOutlined />
                    </Button>
                </Row >

        }
    ];
    return columns;
};
export default Columns;