/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row, Switch } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React from "react";

const colProps = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
const UserFormPermissions = (props: any) => {
    const { canCreateContracts, setCanCreateContracts, canAccessContracts, setCanAccessContracts, canEditContracts, setCanEditContracts, canAccessContractsHistory, setCanAccessContractsHistory, canAccessReportsAndInvoicing, setCanAccessReportsAndInvoicing, canAccessUsers, setCanAccessUsers, canAccessCompanyInfo, setCanAccessCompanyInfo, canAccessCompanyPreferences, setCanAccessCompanyPreferences } = props;
    return (
        <Row>
            <Col {...colProps} style={{ padding: "0.25rem" }}>
                <FormItem valuePropName='can_access_contracts' name="can_access_contracts" style={{ display: 'block' }}>
                    <span><strong>Access Contracts</strong></span>
                    <Switch checked={canAccessContracts} defaultChecked={canAccessContracts} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessContracts(value)} />
                </FormItem>
                <FormItem valuePropName='can_create_contracts' name="can_create_contracts" style={{ display: 'block' }}>
                    <span><strong>Create Contracts</strong></span>
                    <Switch checked={canCreateContracts} defaultChecked={canCreateContracts} style={{ float: 'right' }} onChange={(value: boolean) => setCanCreateContracts(value)} />
                </FormItem>
                <FormItem valuePropName='can_edit_contracts' name="can_edit_contracts" style={{ display: 'block' }}>
                    <span><strong>Edit Contracts</strong></span>
                    <Switch checked={canEditContracts} defaultChecked={canEditContracts} style={{ float: 'right' }} onChange={(value: boolean) => setCanEditContracts(value)} />
                </FormItem>
                <FormItem valuePropName='can_access_contractshistory' name="can_access_contractshistory" style={{ display: 'block' }}>
                    <span><strong>Access Contracts History</strong></span>
                    <Switch checked={canAccessContractsHistory} defaultChecked={canAccessContractsHistory} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessContractsHistory(value)} />
                </FormItem>
                <FormItem valuePropName='can_access_reports_andinvoicing' name="can_access_reports_andinvoicing" style={{ display: 'block' }}>
                    <span><strong>Access Reports and Invoicing</strong></span>
                    <Switch checked={canAccessReportsAndInvoicing} defaultChecked={canAccessReportsAndInvoicing} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessReportsAndInvoicing(value)} />
                </FormItem>
                <FormItem valuePropName='can_access_users' name="can_access_users" style={{ display: 'block' }}>
                    <span><strong>Access Users</strong></span>
                    <Switch checked={canAccessUsers} defaultChecked={canAccessUsers} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessUsers(value)} />
                </FormItem>
                <FormItem valuePropName='can_access_company_info' name="can_access_company_info" style={{ display: 'block' }}>
                    <span><strong>Access Company Info</strong></span>
                    <Switch checked={canAccessCompanyInfo} defaultChecked={canAccessCompanyInfo} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessCompanyInfo(value)} />
                </FormItem>
                <FormItem valuePropName='can_access_company_preferences' name="can_access_company_preferences" style={{ display: 'block' }}>
                    <span><strong>Access Company Preferences</strong></span>
                    <Switch checked={canAccessCompanyPreferences} defaultChecked={canAccessCompanyPreferences} style={{ float: 'right' }} onChange={(value: boolean) => setCanAccessCompanyPreferences(value)} />
                </FormItem>
            </Col>
        </Row>
    );
};

export default UserFormPermissions;