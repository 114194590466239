// Network Utilities
const querystring = require("querystring");

function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
function getCSRFToken() {
  return getCookie('csrftoken');
};

export function postData(url = '', data = {}) {
  const headers = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCSRFToken(),
      referrer: ''
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  } as RequestInit;
  const response = fetch(url, headers);
  return response; // parses JSON response into native JavaScript objects
};

export function postWithFormData(url = '', data: FormData) {
  const headers = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCSRFToken(),
      referrer: ''
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data, // body data type must match "Content-Type" header
  } as RequestInit;
  const response = fetch(url, headers);
  return response; // parses JSON response into native JavaScript objects
};

export function updateData(url = '', data = {}) {
  const headers = {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCSRFToken(),
      referrer: ''
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  } as RequestInit;
  const response = fetch(url, headers);
  return response; // parses JSON response into native JavaScript objects
}

export function updateWithFormData(url = '', data: FormData) {
  const headers = {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // 'Content-Type': "multipart/form-data; boundary=",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCSRFToken(),
      referrer: ''
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data, // body data type must match "Content-Type" header
  } as RequestInit;
  const response = fetch(url, headers);
  return response; // parses JSON response into native JavaScript objects
}

export function getData(url = '', params = {}) {
  // ADD OPTIONAL PARAMS OBJECT TO CREATE QUERY STRING
  let fetchUrl = url;
  if (Object.keys(params).length > 0) {
    const searchParams = querystring.stringify(params);
    fetchUrl = encodeURI(`${url}?${searchParams}`);
  }
  // Default options are marked with *
  const response = fetch(`${fetchUrl}`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      'X-CSRFToken': getCSRFToken()
    }
  } as RequestInit);
  return response; // parses JSON response into native JavaScript objects
};

export function deleteData(url = '') {
  // Default options are marked with *
  const response = fetch(url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      'X-CSRFToken': getCSRFToken()
    }
  } as RequestInit);
  return response; // parses JSON response into native JavaScript objects
}


export default 2;