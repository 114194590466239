/* eslint-disable @typescript-eslint/no-unused-vars */

import { Form, Tabs, Typography } from "antd";
import React, { useState } from "react";
import UploadProfilePicture from "../UploadProfilePicture";
import CompanyFormDetails from "./CompanyFormDetails";
import CompanyFormPreferences from "./CompanyFormPreferences";

const layout = {
    labelCol: { span: 30 },
    wrapperCol: { span: 30 },
};
const CompanyForm = (props: any) => {

    const { form, submitForm, setFile } = props;

    const [activeTab, setActiveTab] = useState('Company Details');

    return (
        <div className="company-form">
            <Form
                form={form}
                labelCol={layout.labelCol}
                wrapperCol={layout.wrapperCol}
                layout="vertical"
                name="update_company_profile"
                id="update_company_profile"
                style={{ padding: '1rem' }}
                initialValues={{ remember: true, currencySymbol: '$' }}
                onFinish={submitForm}
            >
                <Tabs style={{ height: '100%' }} onChange={setActiveTab} type='card' activeKey={activeTab} >
                    <Tabs.TabPane style={{ height: '100%' }} tab='Company Details' key='Company Details'>
                        <Typography.Title>Company Details</Typography.Title>
                        <CompanyFormDetails />
                        <UploadProfilePicture setFile={setFile} />
                    </Tabs.TabPane>
                    <Tabs.TabPane style={{ height: '100%' }} tab='Company Preferences' key='Company Preferences'>
                        <Typography.Title>Company Preferences</Typography.Title>
                        <CompanyFormPreferences />
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </div >
    );
};

export default CompanyForm;