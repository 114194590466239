// Libs
import React, { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Input, Card, Form, Alert, Space, Image } from 'antd';

// Local
import { useAuth } from 'components/auth/authProvider';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const styles = {
  layout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainCard: { maxWidth: '600px', width: '80vw', margin: '10rem auto auto', boxShadow: '0px 0px 5px 1px #4daa48' },
  input: {
    maxWidth: '200px', width: '50vw'
  },
  errorAlert: {
    width: 'max-content',
    maxWidth: '50vw',
    margin: 'auto'
  },
  inputLabel: { fontWeight: 600 },
  title: { paddingTop: '20vh' }
} as { [key: string]: React.CSSProperties };

function LoginPage() {
  const auth = useAuth();
  const history = useHistory();

  const submitForm = useCallback(({ password = '', email = '', remember = false }) => {
    auth.login(email, password, remember, () => history.push('/'));
    // eslint-disable-next-line 
  }, [history]);

  return (
    <div style={styles.layout}>
      <Card style={styles.mainCard}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to='/login'>
            <Image src="/static/cc_logo_fbgreen_512.png" alt='Contract Connection Logo' preview={false} style={{ paddingBottom: '1rem' }} />
          </Link>
        </div>
        <Form
          labelCol={layout.labelCol}
          wrapperCol={layout.wrapperCol}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={submitForm}
        >
          <Form.Item
            label={<div style={styles.inputLabel}>Email</div>}
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
            className="login-page-input"

          >
            <Input style={styles.input} />
          </Form.Item>

          <Form.Item
            label={<div style={styles.inputLabel}>Password</div>}
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            className="login-page-input"
          >
            <Input.Password style={styles.input} />
          </Form.Item>
          {auth.loginErrors ? <Alert message={auth.loginErrors} type='error' style={styles.errorAlert} /> : null}
          {/* <Form.Item wrapperCol={tailLayout.wrapperCol} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

          <Form.Item wrapperCol={tailLayout.wrapperCol}>
            <Button type="primary" htmlType="submit" loading={auth.isAuthorizing || auth.isFetchingUser}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        <Space />
        <div style={{ textAlign: 'center', fontSize: '1rem', paddingTop: '2rem' }}>
          <Link to="/signup">Don&apos;t have an account yet? Sign up Here!</Link>
        </div>
        <div style={{ textAlign: 'center', fontSize: '1rem' }}>
          <a href="api/accounts/password_reset/">Forgot Password?</a>
        </div>
      </Card >
    </div >
  );
};

export default React.memo(LoginPage);
