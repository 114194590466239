/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import moment from 'moment/moment';
import type { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, FileOutlined } from '@ant-design/icons';
import SearchColumn from 'routes/loads/table/SearchColumn';
import React, { useEffect } from 'react';
import { Button, Row, Modal } from 'antd';
import { useContractsApi } from 'routes/contracts/ContractDataProvider';
import * as load from '../model';
import Load from '../types';
import { useLoadsApi } from '../LoadsDataProvider';

const Columns = (props: any) => {
    const { confirm, warning } = Modal;
    const { setEditLoad, deleteLoad } = useLoadsApi();
    const { setRequestRefetch } = useContractsApi();
    const { status } = props;

    const showDeleteConfirm = (load: Load) => {
        if (status !== 'ACTIVE') {
            warning({
                title: 'Warning',
                content: `Loads may only be deleted when the contract is 'Active'. `,
            });
        } else {
            confirm({
                title: 'Are you sure you would like to delete this load?',
                content: 'Deleting a load is permanent. Please make sure you want to delete.',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    deleteLoad(load.id, () => setRequestRefetch(true));
                }
            });
        }
    };

    const GetEditButton = (record: Load): JSX.Element => {
        const button = <Button title='Edit Load' onClick={() => setEditLoad(record)}>
            <EditOutlined />
        </Button>;
        return button;
    };

    const GetDeleteButton = (record: Load): JSX.Element => {
        const button = <Button title='Delete Load' onClick={() => showDeleteConfirm(record)}>
            <DeleteOutlined />
        </Button>;
        return button;
    };

    const GetAttachmentsButton = (record: Load): JSX.Element => {
        const button = <Button title='Attachments' onClick={() => setEditLoad(record)}>
            <FileOutlined />
        </Button>;
        return button;
    };


    const { getColumnSearchProps } = SearchColumn();

    const columns: ColumnsType<Load> = [
        {
            title: 'Load #',
            dataIndex: load.PROP_ID,
            defaultSortOrder: 'descend',
            ...getColumnSearchProps(`${load.PROP_ID}`),
            sorter: (a: Load, b: Load) => a.id - b.id,
        },
        {
            title: 'Dispatch Date',
            dataIndex: load.PROP_DISPATCH_DATE,
            ...getColumnSearchProps(`${load.PROP_DISPATCH_DATE}`),
            sorter: (a: Load, b: Load) => moment(a.dispatch_date).unix() - moment(b.dispatch_date).unix(),
        },
        {
            title: 'Carrier',
            dataIndex: load.PROP_CARRIER,
            ...getColumnSearchProps(`${load.PROP_CARRIER}`),
            sorter: (a: any, b: any) => a.carrier.localeCompare(b.carrier),
        },
        {
            title: 'Arrival Date',
            dataIndex: load.PROP_ARRIVAL_DATE,
            ...getColumnSearchProps(`${load.PROP_ARRIVAL_DATE}`),
            sorter: (a: Load, b: Load) => moment(a.arrival_date).unix() - moment(b.arrival_date).unix(),
        },
        {
            title: 'Scale Ticket #',
            dataIndex: load.PROP_SCALE_TICKET_NUMBER,
            ...getColumnSearchProps(`${load.PROP_SCALE_TICKET_NUMBER}`),
            sorter: (a: any, b: any) => a.scale_ticket_number.localeCompare(b.scale_ticket_number),
        },
        {
            title: 'Qty Rcvd',
            dataIndex: load.PROP_QUANTITY_RECEIVED,
            ...getColumnSearchProps(`${load.PROP_QUANTITY_RECEIVED}`),
            sorter: (a, b) => a.quantity_received - b.quantity_received,
            render: (text, record) => `${new Intl.NumberFormat('en-US').format(record.quantity_received)} t`
        },
        {
            title: '$ Value',
            dataIndex: load.PROP_DOLLAR_VALUE,
            ...getColumnSearchProps(`${load.PROP_DOLLAR_VALUE}`),
            sorter: (a, b) => a.dollar_value - b.dollar_value,
            render: (text, record) => `$${(record.dollar_value ?? '-')}`,
        },
        {
            title: 'Actions',
            dataIndex: '',
            render: (text, record) =>
                <Row>
                    {GetEditButton(record)}
                    {GetDeleteButton(record)}
                    {GetAttachmentsButton(record)}
                </Row >

        }
    ];
    return columns;
};
export default Columns;