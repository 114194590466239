export const PROP_ID = 'id';
export const PROP_CONTRACT_ID = 'contractId';
export const PROP_UUID = 'uuid';
export const PROP_CARRIER = 'carrier';
export const PROP_SCALE_TICKET_NUMBER = 'scale_ticket_number';
export const PROP_DELIVERED_WEIGHT = 'delivered_weight';
export const PROP_DOLLAR_VALUE = 'dollar_value';
export const PROP_QUANTITY_RECEIVED = 'quantity_received';
export const PROP_ARRIVAL_DATE = 'arrival_date';
export const PROP_DISPATCH_DATE = 'dispatch_date';
export const PROP_CREATED_AT = 'created_at';
export const PROP_SELLER_COMPANY = 'seller_company';
export const PROP_LOCATION = 'location';