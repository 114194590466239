export const PROP_LAST_LOGIN = 'last_login';
export const PROP_DATE_JOINED = 'date_joined';
export const PROP_IS_SUPERUSER = 'is_superuser';
export const PROP_IS_ACTIVE = 'is_active';
export const PROP_IS_STAFF = 'is_staff';
export const PROP_ID = 'id';
export const PROP_COMPANY = 'company';
export const PROP_COMPANY_ID = 'company_id';
export const PROP_FIRST_NAME = 'first_name';
export const PROP_LAST_NAME = 'last_name';
export const PROP_EMAIL = 'email';
export const PROP_ACCOUNT_TYPE = 'account_type';
export const PROP_JOB_TITLE = 'job_title';
export const PROP_USER_PHONE = 'user_phone';
export const PROP_USER_FAX = 'user_fax';
export const PROP_CAN_CREATE_CONTRACTS = 'can_create_contracts';
export const PROP_CAN_ACCESS_CONTRACTS = 'can_access_contracts';
export const PROP_CAN_EDIT_CONTRACTS = 'can_edit_contracts';
export const PROP_CAN_ACCESS_CONTRACTS_HISTORY = 'can_edit_contracts_history';
export const PROP_CAN_ACCESS_REPORTS_AND_INVOICING = 'can_access_reports_and_invoicing';
export const PROP_CAN_ACCESS_USERS = 'can_access_users';
export const PROP_CAN_ACCESS_COMPANY_INFO = 'can_access_company_info';
export const PROP_CAN_ACCESS_COMPANY_PREFERENCES = 'can_access_company_preferences';
export const PROP_EMPLOYEE_TYPE = 'employee_type';