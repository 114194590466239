import { Button, Col, Modal, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";

const styles = {
    message: {
        backgroundColor: 'whitesmoke',
        padding: '1rem',
        borderRadius: '0.5rem',
        fontSize: '16px',
    }
} as { [key: string]: React.CSSProperties };
const WebsiteMessagesModal = (props: any) => {
    const {
        messages,
        isWebsiteMessagesModalVisible,
        setIsWebsiteMessagesModalVisible,
        fetchWebsiteMessages,
        updateWebsiteMessageDismissed,
        isUpdatingWebsiteMessageDismissed
    } = props;
    const [currentMessage, setCurrentMessage] = useState(0);
    const [dismissedIndex, setDismissedIndex] = useState(-1);

    useEffect(() => {
        if (messages && messages.length > 0 && dismissedIndex > -1) {
            setCurrentMessage(dismissedIndex <= 1 ? 0 : dismissedIndex - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    const handlePrev = () => {
        if (currentMessage === 0) {
            setCurrentMessage(messages.length - 1);
        } else {
            setCurrentMessage(currentMessage - 1);
        }
    };
    const handleNext = () => {
        if (currentMessage === messages.length - 1) {
            setCurrentMessage(0);
        } else {
            setCurrentMessage(currentMessage + 1);
        }
    };
    const handleClose = () => {
        setCurrentMessage(0);
        setDismissedIndex(-1);
        setIsWebsiteMessagesModalVisible(false);
    };

    const handleOnDismissSuccess = (index: number) => {
        setDismissedIndex(index);
        if (messages.length === 1) {
            handleClose();
        }
        fetchWebsiteMessages();
    };

    const handleDismiss = () => {
        updateWebsiteMessageDismissed(messages[currentMessage].id, () => handleOnDismissSuccess(currentMessage));
    };
    return <Modal
        title={<Row>
            <Col style={{ marginRight: 'auto' }}>
                <Typography.Text>What is New?</Typography.Text>
            </Col>
            <Col style={{ marginLeft: 'auto', marginRight: '1rem' }}>
                <Typography.Text>Message: {currentMessage + 1}/{messages.length}</Typography.Text>
            </Col>
        </Row>}
        visible={isWebsiteMessagesModalVisible}
        footer={messages?.length > 1 &&
            <Row>
                <Col style={{ marginRight: 'auto' }}>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </Col>
                <Col style={{ marginLeft: 'auto' }}>
                    <Button key="prev" type="primary" onClick={handlePrev}>
                        Prev
                    </Button>
                    <Button key="next" type="primary" onClick={handleNext}>
                        Next
                    </Button>
                </Col>
            </Row>
        }
        onCancel={handleClose}
    >
        <Spin spinning={isUpdatingWebsiteMessageDismissed}>
            <Row>
                <Col>
                    <Typography.Title level={5}>
                        Message:
                    </Typography.Title>
                </Col>
            </Row>
            <Row style={styles.message}>
                <Typography.Text>{messages[currentMessage]?.message}</Typography.Text>
            </Row>
            <Row>
                <Col style={{ paddingTop: '1rem', width: '100%' }}>
                    <Button style={{ width: '100%' }} type="primary" onClick={handleDismiss}>
                        Do not show again
                    </Button>
                </Col>
            </Row>
        </Spin>
    </Modal >;
};

export default WebsiteMessagesModal;