import React, { useEffect, useState } from "react";
import { Badge, Typography } from 'antd';
import { MessageOutlined } from "@ant-design/icons";
import WebsiteMessagesModal from "./WebsiteMessagesModal";
import { useAuth } from "./authProvider";

const styles = {
    whatsNew: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
} as { [key: string]: React.CSSProperties };
const WebsiteMessagesButton = (props: any) => {
    const { isSiderCollapsed } = props;

    const {
        isLoggedIn,
        isFetchingWebsiteMessages,
        fetchWebsiteMessages,
        websiteMessages,
        updateWebsiteMessageDismissed,
        isUpdatingWebsiteMessageDismissed,
    } = useAuth();

    const [isWebsiteMessagesModalVisible, setIsWebsiteMessagesModalVisible] = useState(false);

    useEffect(() => {
        if (!isFetchingWebsiteMessages && isLoggedIn) {
            fetchWebsiteMessages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && websiteMessages && (websiteMessages.length > 0)) {
            setIsWebsiteMessagesModalVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, websiteMessages]);

    return <div className="website-messages">
        {websiteMessages && websiteMessages.length > 0 &&
            <div role='button' style={styles.whatsNew} onClick={() => { setIsWebsiteMessagesModalVisible(true); }} onKeyDown={() => { }} tabIndex={0}>
                <Badge count={websiteMessages.length} offset={[5, 0]} size="small">
                    <MessageOutlined style={{ color: 'rgba(255, 255, 255, 0.65)' }} />
                    {!isSiderCollapsed && <Typography.Text style={{ color: 'rgba(255, 255, 255, 0.65)', marginLeft: '8px' }}>What&lsquo;s New</Typography.Text>}
                </Badge>
            </div>
        }

        <WebsiteMessagesModal
            messages={websiteMessages}
            setIsWebsiteMessagesModalVisible={setIsWebsiteMessagesModalVisible}
            fetchWebsiteMessages={fetchWebsiteMessages}
            isWebsiteMessagesModalVisible={isWebsiteMessagesModalVisible}
            isUpdatingWebsiteMessageDismissed={isUpdatingWebsiteMessageDismissed}
            updateWebsiteMessageDismissed={updateWebsiteMessageDismissed}
        />
    </div>;
};

export default WebsiteMessagesButton;