import React from 'react';

import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAuth } from './authProvider';

const LogoutButton = () => {
    const auth = useAuth();
    const { push } = useHistory();
    return (
        <Button className="btn-primary" type="primary" loading={auth.isAuthorizing || auth.isFetchingUser} onClick={() => auth.logout(() => push('/login'))}>Logout</Button>

    );
};

export default LogoutButton;
