/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useAuth } from 'components/auth/authProvider';
import moment from 'moment/moment';
import Highlighter from 'react-highlight-words';
import { getStatusText } from 'utils/dataUtils';
import type { ColumnsType } from 'antd/lib/table';
import { Button, Modal, Row } from 'antd';
import { CarOutlined, CheckOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import * as contract from '../model';
import { Contract } from '../types';


const styles = {
    badge: {
        display: 'inline-block',
        padding: '0.25em 0.4em',
        fontSize: 'smaller',
        fontWeight: 700,
        lineHeight: 1,
        textAlign: 'center',
        verticalAlign: 'baseline',
        borderRadius: '0.25rem',
        width: '100%'
    },

    badgeDanger: {
        color: '#fff',
        backgroundColor: '#dc3545',
    },

    badgeSuccess: {
        color: '#fff',
        backgroundColor: '#28a745',
    },

    badgeWarn: {
        color: '#212529',
        backgroundColor: '#ffc107',
    }

} as { [key: string]: React.CSSProperties };
const Columns = (props: { searchValue: string[], deleteContract: any, completeContract: any, cancelContract: any, downloadContractPDF: any, isDownloadingContractPDF: boolean, contractNotifications: { id: number, notifications: string[] }[] }) => {
    const { searchValue, deleteContract, completeContract, cancelContract, downloadContractPDF, isDownloadingContractPDF, contractNotifications } = props;
    const { user } = useAuth();
    const { confirm } = Modal;

    const showDeleteConfirm = (id: number) => {
        confirm({
            title: 'Are you sure you would like to delete this contract?',
            content: 'Deleting a contract is permanent. Please make sure you want to delete.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteContract(id);
            }
        });
    };
    const showCancelConfirm = (id: number, purchaser: boolean) => {
        confirm({
            title: 'Are you sure you would like to Cancel this contract?',
            content: 'Cancelling this contract will send a request to the other party, once confirmed the contract will be cancelled.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                cancelContract(id, purchaser, false);
            }
        });
    };
    const showCompleteConfirm = (id: number, purchaser: boolean) => {
        confirm({
            title: 'Are you sure you would like to complete this contract?',
            content: 'Completing a contract is permanent. Please make sure you want to complete the contract.',
            okText: 'Yes',
            okType: 'default',
            cancelText: 'No',
            onOk() {
                completeContract(id, purchaser, false);
            }
        });
    };


    const { push } = useHistory();
    const GetEditButton = (record: Contract): JSX.Element => {
        const { id } = record;
        const button = <Button title="Edit Contract" onClick={() => push(`/contracts/edit/${id}`)}>
            <EditOutlined />
        </Button>;
        return button;
    };

    const GetAddLoadButton = (record: Contract): JSX.Element => {
        const { id } = record;
        const button = <Button title="Edit Loads" onClick={() => push(`contracts/edit/${id}/loads`)}>
            <CarOutlined />
        </Button>;
        return button;
    };

    const GetDownloadContractButton = (record: Contract): JSX.Element => {
        const { id } = record;
        const button = <Button title="Download Contract" onClick={() => downloadContractPDF(id)}>
            <DownloadOutlined />
        </Button>;
        return button;
    };

    const GetCompleteContractButton = (contractId: number, purchaser: boolean): JSX.Element => <Button title="Complete Contract" onClick={() => showCompleteConfirm(contractId, purchaser)}><CheckOutlined /></Button>;

    const render = (text: string) => text && searchValue.length > 0 && searchValue.some(s => text.toLowerCase().includes(s.toLowerCase())) ? (
        <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={searchValue}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />
    ) : (
        text
    );

    const columns: ColumnsType<Contract> = [
        {
            title: 'Status',
            dataIndex: '',
            render: (text, record) => {
                switch (record.status) {
                    case 'SIGNATURE_REQUIRED':
                    case 'SIGNATURE_PENDING':
                    case 'SELLER_REQUESTED_COMPLETION':
                    case 'PURCHASER_REQUESTED_COMPLETION':
                    case 'SELLER_REQUESTED_CANCELLATION':
                    case 'PURCHASER_REQUESTED_CANCELLATION':
                    case 'SELLER_REQUESTED_EDIT':
                        return <span style={{ ...styles.badge, ...styles.badgeWarn }}>{getStatusText(record.status)}</span>;
                    case 'ACTIVE':
                        return <span style={{ ...styles.badge, ...styles.badgeSuccess }}>{getStatusText(record.status)}</span>;
                    default:
                        break;
                }
                if (record.cancelled) {
                    return <span style={{ ...styles.badge, ...styles.badgeDanger }}>Cancelled</span>;
                }
                if (record.completed) {
                    return <span style={{ ...styles.badge, ...styles.badgeSuccess }}>Completed</span>;
                }
                return <></>;
            }
        },
        {
            title: 'Seller',
            dataIndex: contract.PROP_SELLER_COMPANY,
            sorter: (a: any, b: any) => a.seller_company.name.localeCompare(b.seller_company.name),
            render: item => render(item.name),
        },
        {
            title: 'Purchaser',
            dataIndex: contract.PROP_PURCHASER_COMPANY,
            sorter: (a: any, b: any) => a.purchaser_company.name.localeCompare(b.purchaser_company.name),
            render: item => render(item.name),
        },
        {
            title: 'Contract #',
            dataIndex: contract.PROP_ID,
            defaultSortOrder: 'descend',
            sorter: (a: Contract, b: Contract) => a.id - b.id,
            render: (text, record) => render(record.id.toString())
        },
        {
            title: 'Purchase Date',
            dataIndex: contract.PROP_PURCHASE_DATE,
            sorter: (a: Contract, b: Contract) => moment(a.purchase_date).unix() - moment(b.purchase_date).unix(),
            render: (text, record) => render(record.purchase_date.toString())
        },
        {
            title: 'Timeframe',
            dataIndex: contract.PROP_START_DATE,
            sorter: (a: Contract, b: Contract) => moment(a.start_date).unix() - moment(b.start_date).unix(),
            render: (text, record) => render(`${record.start_date} - ${record.end_date}`),
        },
        {
            title: 'Location',
            dataIndex: contract.PROP_LOCATION,
            sorter: (a, b) => a.location.localeCompare(b.location),
            render: (text, record) => render(record.location)
        },
        {
            title: 'Principle Qty',
            dataIndex: contract.PROP_PRINCIPAL_QUANTITY,
            sorter: (a, b) => a.quantity - b.quantity,
            render: (text, record) => render(`${new Intl.NumberFormat('en-US').format(record.quantity)} t`)
        },
        {
            title: 'Remaining Qty',
            dataIndex: contract.PROP_QUANTITY_REMAINING,
            sorter: (a, b) => a.quantity_remaining - b.quantity_remaining,
            render: (text, record) => render(`${new Intl.NumberFormat('en-US').format(record.quantity_remaining)} t`)
        },
        {
            title: 'Price',
            dataIndex: contract.PROP_PRICE,
            sorter: (a, b) => a.price - b.price,
            render: (text, record) => render(`$${record.price}`),
        },
        {
            title: 'Commodity',
            dataIndex: contract.PROP_COMMODITY,
            sorter: (a: Contract, b: Contract) => a.commodity.localeCompare(b.commodity),
            render: (text, record) => render(record.commodity)
        },
        {
            title: 'Actions',
            dataIndex: '',
            render: (text, record) => {
                if (record.cancelled || record.completed) { return <></>; }
                return (
                    < Row >
                        {record.signature && GetDownloadContractButton(record)}
                        {GetEditButton(record)}
                        {record.signature && record.status === 'ACTIVE' && (
                            < Button title="Cancel Contract" onClick={() => showCancelConfirm(record.id, record.purchaser_company.id === user.company_id)}>
                                <StopOutlined />
                            </Button >
                        )}
                        {!record.signature && (record.status === 'ACTIVE' || record.status === "SIGNATURE_REQUIRED") && (
                            < Button title="Delete Contract" onClick={() => showDeleteConfirm(record.id)}>
                                <DeleteOutlined />
                            </Button >
                        )
                        }
                        {GetAddLoadButton(record)}
                        {
                            record.quantity_remaining <= 100 && record.status === 'ACTIVE' &&
                            GetCompleteContractButton(record.id, record.purchaser_company.id === user.company_id)
                        }
                    </Row >
                );
            }
        }
    ];
    return columns;
};
export default Columns;