/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Form, InputNumber, Modal, Row, Table } from 'antd';
import { useParams } from 'react-router-dom';
import type { TableProps } from 'antd/lib/table';
import { getStatusText } from 'utils/dataUtils';
import { LeftOutlined } from '@ant-design/icons';
import { useAuth } from 'components/auth/authProvider';
import tableColumns from './columns';
import { useLoadsApi } from '../LoadsDataProvider';
import Load from '../types';
import '../loads.css';
import LoadForm from '../LoadForm';
import LoadAttachments from '../LoadAttachments';


const LoadsTable = (props: any) => {
    const { contractId, isSeller, isSigned, pricePerTonne, status, editMode, setEditMode, setLoading, setActiveTab } = props;
    const { loadId }: { loadId: string } = useParams();

    const { fetchLoadsByContractId, loads, loadInEditMode, setEditLoad, postLoad } = useLoadsApi();
    const auth = useAuth();
    const [form] = Form.useForm();
    const { warning } = Modal;

    const showWarningConfirm = (confirmContent = 'Adding loads cannot be done until the contract has been signed by the Purchaser.') => {
        warning({
            title: 'Warning',
            content: confirmContent,
        });
    };

    useEffect(() => {
        if (Object.keys(loads).length === 0 && contractId) {
            fetchLoadsByContractId(contractId, () => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [contractId]);

    useEffect(() => {
        setEditMode(Object.keys(loadInEditMode).length > 0 && !editMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadInEditMode]);

    useEffect(() => {
        if (Object.keys(loads).length > 0 && loadId && !editMode) {
            setEditLoad(loads.find(x => x.id === parseInt(loadId, 10)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, loads]);

    const columns = tableColumns({ status });
    // FILTER ON CONTRACT ID TEMPORARY
    const data = useMemo(() => Object.values(loads).sort((a, b) => b.id - a.id), [loads]);
    const onChange: TableProps<Load>['onChange'] = () => { };
    const AddLoad = () => {
        if (!isSigned) {
            showWarningConfirm();
            return;
        }
        if (!isSeller) {
            showWarningConfirm('As the Purchaser you are not permitted to create new loads.');
            return;
        }
        if (status !== 'ACTIVE') {
            showWarningConfirm(`Loads cannot be added while contract status is: ${getStatusText(status)}`);
        }
        // SHOW 
        const values = form.getFieldsValue();
        const loadsToAdd: Load[] = [];
        for (let i = 0; i < values.loadCount; i += 1) {
            loadsToAdd.push(new Load({ id: 0, uuid: auth.user.company_id, contract: +contractId, carrier: null, arrival_date: null, delivered_weight: null, dispatch_date: null, dollar_value: null, quantity_received: null, scale_ticket_number: null }));
        }
        postLoad(loadsToAdd);

        values.loadCount = 1;
        form.setFieldsValue(values);
    };
    if (editMode) {
        return (
            <div>
                <Button key="Back" type="default" onClick={() => { setEditMode(false); setEditLoad(); }}> <LeftOutlined /> Back </Button>
                <br />
                <Button style={{ marginTop: '0.5rem' }} key="View Contract" type="default" onClick={() => { setEditMode(false); setEditLoad(); setActiveTab('contract'); }}> <LeftOutlined /> View Contract </Button>

                <LoadForm load={loadInEditMode} setEditMode={setEditMode} isSeller={isSeller} pricePerTonne={pricePerTonne} contractId={contractId} status={status} />
                <Divider type='horizontal' />
                <LoadAttachments loadId={(loadInEditMode as Load).id} />
            </div>
        );
    }
    return (
        <div>
            {
                contractId &&
                <Form form={form}
                    initialValues={{ loadCount: 1 }}
                >
                    <Row>
                        <Col span={6} offset={8}>
                            <Form.Item name="loadCount" label="Number of Loads">
                                <InputNumber key="Load Count" placeholder="Number of Loads to Create..." style={{ marginBottom: "0.5rem", width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button key="Add Load(s)" style={{ width: "100%", marginBottom: "0.5rem" }} type="primary" onClick={AddLoad}>
                                + New Load
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
            <Table
                columns={columns}
                dataSource={data}
                onChange={onChange}
                scroll={{ x: 900 }}
                className="load-table"
                tableLayout='fixed' />
        </div >
    );

};

export default LoadsTable;
